
import styled from 'styled-components';

const EstiloTag = styled.div`

    .etiquetas {
        width: 85%;
        display: left;
        padding: 10px 10px 10px 10px;
        margin: 20px 20px 20px 20px; 
        border: solid #a9a9a9 1px;
        border-radius: 6px;
        background-color:white;
        float: left;
    }

    .etiqueta {
        display: inline-block;
        align-items: center;
        color: #000;
        padding: 10px 10px;
        margin: 5px 10px 5px 10px; 
        font-size: 12px;
        list-style: none;
        border-radius: 6px;
        background: ${({theme}) => theme.tmAjuda};
    }

`;

interface TagsProps {
    lista:string[];
}
const Tags = ({lista}:TagsProps) => {
    return(
        <EstiloTag className="etiquetas-input">
            <ul className="etiquetas">
                {lista.map((item) => (
                    <li className='etiqueta' key={`etiqueta-${item}`}>
                        <span className='etiqueta-title' key={`etiqueta-title-${item}`}>{item}</span>
                    </li>
                ))}
            </ul>
        </EstiloTag>
    );
};

export default Tags;