import React, { useState } from "react";
import styled from 'styled-components';
import { tratamentoNomeCampo } from '../../js/convesoes';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import relatorios from '../../json/relatorios.json';
import GrupoBotoes from '../../componentes/GrupoBotoes/index';
import Grupo from '../../componentes/Grupo/index';
import { FaMinusCircle , FaPlusCircle, FaCopy} from "react-icons/fa";
import ConteudoRelatorio from '../../componentes/ConteudoRelatorio/index';
import Tags from '../../componentes/Tags/index';
import { ImPrinter } from "react-icons/im";
import { IoMdSave } from "react-icons/io";

 
const EstiloPaginaRelatorioDinamico = styled.div`
    background-color: ${({ theme }) => theme.tmCorpo};
    display:table;
    .formulario{
        width: 80%;
        padding: 0px 0px 0px 0px ;
    }

    #btn-copiar-relatorio{
        width: 30px;
        height: 30px;
        float:right;
        margin: 0px 50px 0px 0px;
        color: ${({ theme }) => theme.tmTituloFundo};
        
        &:hover {
            border: none;
            color: ${({ theme }) => theme.tmAjuda};
            transition: .3s;
            cursor:pointer;
        }
    }

    #tb-formulario th{
        width: 100%;
        text-align: left;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
    }

    @media (min-width: 320px) and (max-width: 480px) {

        /* ----------- botoes imprimir e salvar --------*/
        #btn-imprimir, #btn-salvar-relatorio {
            width: 30px;
            height: 30px;
            float:left;
            margin: 20px 30px 0px 5px;
            color: ${({ theme }) => theme.tmTituloFundo};
            /* background-color: #4E6E81; */ 

            &:hover {
                border: none;
                color: ${({ theme }) => theme.tmAjuda};
                transition: .3s;
                cursor:pointer;
            }
        }  

        #btn-copiar-codigo, #btn-salvar-codigo{
            width: 30px;
            height: 30px;
            float:left;
            margin: 20px 20px 0px 20px;
            color: ${({ theme }) => theme.tmTituloFundo};
            /* background-color: #4E6E81;  */

            &:hover {
                border: none;
                color: ${({ theme }) => theme.tmAjuda};
                transition: .3s;
                cursor:pointer;
            }
        }  

        #grp-botoes {
            width: 65%;
            height: 100px;
            display: table;
            margin: 20px 20px 40px 20px;
            padding: 0px 0px 0px 0px;   
        }

        #grp-botoes .tituloGrupo {
            width: 100%;
            height: 30px;
            padding: 0px 0px 0px 0px;
            margin: 0px 0px 0px 0px;
            display: table;
        }

        #grpb-zoom {
            width: 90%;
            height: 60px;
            margin: 10px 20px 10px 10px;
            padding: 0px 0px 0px 0px;
            display: table;
        }

        .legGrupoBotoes {
            margin: 0px 0px 4px 0px;
        }

        .botao{
            color:${({theme}) => theme.tmBotao};
            width: 80px;
            height: 30px;
            margin: 0px 0px;
            padding: 0px 0px 0px 0px;

            &:hover{
                color:${({theme}) => theme.tmBotaoHover};
            }
        }

        /* ----------- area do bloco de notas---------*/

        .etiquetacodigo {
            float: left;
            background-color: transparent; 
            margin: 20px 0px 20px 20px;
            padding: 0px 0px;
            font-weight: bold; 
        }

        #bloco-notas{
            float: left;
            width: 100%;
            height: 300px;
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            font-size: ${localStorage.getItem('trpTamanhoFonte')}px;
            resize: vertical;
        }

        /* ----------- area do formulário---------*/

        .area-botao-script{
            background-color: ${({ theme }) => theme.tmCorpo};
            float: left; 
            height: 0px;
            width: auto;
            margin: 25px 5px 0px 20px;
            padding: 0px 0px 0px 0px;
            display: table;
        } 

        .etiquetaformulario {
            float: left; 
            width: 100%;
            background-color: ${({ theme }) => theme.tmCorpo};
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 20px 0px;
            font-weight: bold;
            /* background-color: #5B8FB9; */
        }

        .area-do-formulario {
            float: left;
            /* background-color: ${({ theme }) => theme.tmCorpo}; */
            width: 80%;
            height: 150px;  
            margin: 40px 5px 0px 20px;
            padding: 0px 0px 0px 0px;
            display: table;
            /* background-color: #301E67;  */
        }

        #formulario-relatorio-dinamico {
            /* float: left;  */
            background-color: ${({ theme }) => theme.tmAjuda};
            width: 90%; 
            height: 150px;  
            margin: 40px 60px 20px 0px;
            padding: 20px 0px 0px 0px;
            display: table;
            /* background-color: #B05A7A;  */
        }

        label{
            padding: 0px 0px 0px 0px;
            margin: 0px 0px 20px 20px;
            /* color: #301E67; */
        }

        table{
            margin: 0px 0px 20px 0px;
            padding: 0px 0px 10px 0px;
            /* background-color: #93C6E7; */
            display: table;
            
            tr{
                margin: 0px 0px 20px 20px;
                padding: 20px 0px 20px 20px;
                /* background-color: #F48484; */
                        
                th{
                    margin: 0px 0px 10px 20px;
                    padding: 10px 0px 10px 20px;
                    /* background-color: #FFEA20; */
                    
                    label{
                        line-height: 1.0rem;
            
                    }

                    input{
                        margin: 0px 60px 0px 0px;
                        padding: 0px 10px 0px 0px;
                    }
                }
            }
        }

        /* ----------- area do relatório---------*/

        .area-botao-relatorio {
            background-color: ${({ theme }) => theme.tmCorpo};
            float: left; 
            height: 0px;
            width: auto;
            margin: 25px 5px 0px 20px;
            padding: 0px 0px 0px 0px;
            display: table;
        }

        .etiquetarelatorio {
            float: left;
            background-color: ${({ theme }) => theme.tmCorpo};
            margin: 0px 0px 30px 0px;
            padding: 0px 0px 0px 0px;
            font-weight: bold; 
        }

        .area-do-relatorio {
            float: left;
            width: 80%;
            margin: 40px 0px 40px 20px;
            padding: 0px 0px 0px 0px;
            display: table; 
            /* background-color: #84D2C5; */
        }

        #container-conteudo-relatorio {
            width: 85%;
            margin: 0px 0px 20px 0px;
        }

        #container-conteudo-relatorio h1{
            margin: 0px 0px 20px 0px;
        }

        #container-conteudo-relatorio p{
            margin: 0px 0px 0px 0px;
            line-height: 30px;
            font-size: 14px;
            
        }

        #relatorio, #codigo{
            float: left;
            width: 100%;
            margin: 40px 0px 40px 0px;
            padding: 10px 10px 10px 10px;
            display: table; 
        }

    }

    @media (min-width: 481px) {

        /* ----------- botoes imprimir e salvar --------*/
        #btn-imprimir, #btn-salvar-relatorio {
            width: 30px;
            height: 30px;
            float:left;
            margin: 20px 30px 0px 5px;
            color: ${({ theme }) => theme.tmTituloFundo};
            /* background-color: #4E6E81; */ 

            &:hover {
                border: none;
                color: ${({ theme }) => theme.tmAjuda};
                transition: .3s;
                cursor:pointer;
            }
        }  

        #btn-copiar-codigo, #btn-salvar-codigo{
            width: 30px;
            height: 30px;
            float:left;
            margin: 20px 20px 0px 20px;
            color: ${({ theme }) => theme.tmTituloFundo};
            /* background-color: #4E6E81;  */

            &:hover {
                border: none;
                color: ${({ theme }) => theme.tmAjuda};
                transition: .3s;
                cursor:pointer;
            }
        }  

        /* ----------- area dos botoes de zoom---------*/

        #grp-botoes {
            width: fit-content;
            height: 100px;
            display: table;
            margin: 20px 20px 40px 20px;
            padding: 0px 0px 0px 0px;
        }

        #grp-botoes .tituloGrupo {
            width: 100%;
            height: 30px;
            padding: 0px 0px 0px 0px;
            margin: 0px 0px 0px 0px;
            display: table;
        }

        #grpb-zoom {
            width: 90%;
            height: 60px;
            margin: 10px 20px 10px 10px;
            padding: 0px 0px 0px 0px;
            display: table;
        }

        .legGrupoBotoes {
            margin: 0px 0px 4px 0px;
        }

        .botao{
            color:${({theme}) => theme.tmBotao};
            width: 80px;
            height: 30px;
            margin: 0px 0px;
            padding: 0px 0px 0px 0px;

            &:hover{
                color:${({theme}) => theme.tmBotaoHover};
            }

        }

        /* ----------- area do bloco de notas---------*/

        .etiquetacodigo {
            float: left;
            background-color: transparent; 
            margin: 20px 0px 20px 20px;
            padding: 0px 0px;
            font-weight: bold; 
        }

        #bloco-notas{
            float: left;
            width: 100%;
            height: 400px;
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            font-size: ${localStorage.getItem('trpTamanhoFonte')}px;
            resize: vertical;
        }
            /* ----------- area do formulário---------*/

        .area-botao-script{
            background-color: ${({ theme }) => theme.tmCorpo};
            float: left; 
            height: 0px;
            width: auto;
            margin: 25px 5px 0px 20px;
            padding: 0px 0px 0px 0px;
            display: table;
        /*  background-color: #B05A7A; */
        } 

        .etiquetaformulario {
            float: left; 
            width: 100%;
            background-color: ${({ theme }) => theme.tmCorpo};
            margin: 0px 0px 40px 0px;
            padding: 0px 0px 40px 0px;
            font-weight: bold;
            background-color: #fff; 
        }

        .area-do-formulario {
            float: left;
            width: 95%;
            height: 150px;  
            margin: 40px 5px 0px 20px;
            padding: 0px 0px 0px 0px;
            display: table;
        }

        #formulario-relatorio-dinamico {
            background-color: ${({ theme }) => theme.tmAjuda};
            width: 100%; 
            height: 150px;  
            margin: 0px 0px 20px 0px;
            padding: 20px 0px 0px 0px;
            display: table;
        }

        label{
            padding: 0px 0px 0px 0px;
            margin: 0px 0px 0px 20px;
        }

        table{
            margin: 0px 0px 20px 0px;
            padding: 0px 0px 10px 0px;
            display: table;

            tr{
                margin: 0px 0px 20px 20px;
                padding: 20px 0px 20px 20px;

                th{
                    margin: 0px 0px 10px 20px;
                    padding: 10px 0px 10px 20px;

                    label{
                        line-height: 1.0rem;
                    }

                    input{
                        margin: 0px 10px 0px 0px;
                        padding: 0px 10px 0px 0px;
                    }
                }
            }
        }

        /* ----------- area do relatório---------*/

        .area-botao-relatorio {
                background-color: ${({ theme }) => theme.tmCorpo};
                float: left; 
                height: 0px;
                width: auto;
                margin: 25px 5px 0px 20px;
                padding: 0px 0px 0px 0px;
                display: table;

        } 

        .etiquetarelatorio {
            float: left;
            background-color: ${({ theme }) => theme.tmCorpo};
            margin: 0px 0px 0px 0px;
            padding: 0px 0px;
            font-weight: bold; 
        }

        .area-do-relatorio {
            float: left;
            width: 80%;
            margin: 10px 10px 10px 10px;
            padding: 0px 0px 0px 0px;
            display: table; 

        }

        #relatorio, #codigo{
            float: left;
            width: 100%;
            margin: 40px 0px 40px 0px;
            padding: 10px 10px 10px 10px;
            display: table; 
        }

        #container-conteudo-relatorio {
            width: 95%;
            margin: 0px 0px 20px 0px;
        }

        #container-conteudo-relatorio h1{
            margin: 0px 0px 20px 0px;
        }

        #container-conteudo-relatorio p{
            margin: 0px 0px 0px 0px;
            line-height: 30px;
            font-size: 14px;
            
        }
    }
`;

export default function PaginaRelatorioDinamico() {

    const parametros = useParams();
    const [getInicio,setInicio] = useState(true);
    const [getFormulario,setFormulario] = useState("");
    const [getRelatorio,setRelatorio] = useState("");
    var [getTamanhoFonte,setTamanhoFonte] = useState(parseInt(localStorage.getItem('trpTamanhoFonte')));

    const itens = new Map();

    const relatorio = relatorios.find((relatorio) => {
        return relatorio.id === Number(parametros.id);
    });

    if(getInicio){
        const formulario = {
            codigo:relatorio.codigo,
            campos:[],
            resultado:''
        };
        setFormulario(formulario);
        setInicio(false);
    }

    setTimeout(function(){
            document.getElementById('bloco-notas').value = getFormulario.codigo;
            document.getElementById('btn-cria-relatorio').disabled = true;
            document.getElementById('btn-cria-relatorio').disabled = false;
    },500);

    function copia_codigo(){
        let codigo = document.querySelector("#bloco-notas");
        codigo.select();
        document.execCommand('copy');
    }

    function atualiza_formulario(){
        var formulario = getFormulario;
        formulario.codigo = document.getElementById('bloco-notas').value;
        var lista = formulario.codigo.matchAll(/(!!)(.{1}|.{2}|.{3}|.{4}|.{5}|.{6}|.{7}|.{8}|.{9}|.{10}|.{11}|.{12}|.{13}|.{14}|.{15}|.{16}|.{17}|.{18}|.{19}|.{20}|.{21}|.{22}|.{23}|.{24}|.{25}|.{26}|.{27}|.{28}|.{29}|.{30}|.{31}|.{32}|.{33}|.{34}|.{35}|.{36}|.{37}|.{38}|.{39}|.{40}|.{41}|.{42}|.{43}|.{44}|.{45}|.{46}|.{47}|.{48}|.{49}|.{50}|.{60}|.{61}|.{62}|.{63}|.{64}|.{65}|.{66}|.{67}|.{68}|.{69}|.{70}|.{71}|.{72}|.{73}|.{74}|.{75}|.{76}|.{77}|.{78}|.{79}|.{80}|.{81}|.{82}|.{83}|.{84}|.{85}|.{86}|.{87}|.{88}|.{89}|.{90}|.{91}|.{92}|.{93}|.{94}|.{95}|.{96}|.{97}|.{98}|.{99})(!!)/g);
        var inicio = 0;
        var final = 0;
        var campo = '';
        var ordem = 0;
        for (const item of lista){
            inicio = item.index +2 ;
            final = item.index + item[0].length -2;
            itens.set(formulario.codigo.substring(inicio, final));
        }
        
        /* Itens é um MAP o que garante que não haverá campos iguais */
        formulario.campos = [];
        for(const item of itens){
            ordem = ordem + 1;
            campo = {
                id:`ipt-txt-${tratamentoNomeCampo(item[0])}`,
                valor:'',
                desc: item[0],
                tipo_dado:'string',
                tipo_obj:'ipt-txt'
            }
            formulario.campos.push(campo);
        }

        const pai = document.getElementById("tb-formulario");
        while (pai.hasChildNodes()) {
            pai.removeChild(pai.firstChild);
        }
        var novo_obj = '';
        var linha = '';
        var coluna_esq = '';
        var coluna_dir = '';
        for(campo in formulario.campos){
            linha = document.createElement("tr");
            coluna_esq = document.createElement("th");
            novo_obj = document.createElement("label");
            novo_obj.className = `lbl-formulario`;
            novo_obj.innerHTML = formulario.campos[campo].desc + ': ';
            coluna_esq.appendChild(novo_obj);
            coluna_dir = document.createElement("th");
            novo_obj = document.createElement("input");
            novo_obj.className = `ipt-formulario`;
            novo_obj.id = formulario.campos[campo].id;
            novo_obj.value = formulario.campos[campo].valor;
            coluna_dir.appendChild(novo_obj);
            linha.appendChild(coluna_esq);
            linha.appendChild(coluna_dir);
            pai.appendChild(linha);
        }
        document.getElementById('btn-cria-relatorio').disabled = false;
        setFormulario(formulario);
    }

    function atualiza_relatorio(){
        var formulario = getFormulario;
        /* Montar o Relatório*/
        var texto = document.getElementById('bloco-notas').value;
        formulario.codigo = texto;
        var busca = '';
        var substituir = '';
        for( const campo in formulario.campos){
            busca = formulario.campos[campo].desc;
            substituir = document.getElementById(formulario.campos[campo].id).value
            texto = texto.replaceAll(`!!${busca}!!`,substituir);
        }
        formulario.relatorio = texto;
        setFormulario(formulario);
        setRelatorio(texto);

        texto.toString(texto);
        /* Atualiza resultado do formulario */
        setFormulario.resultado = texto;
    }

    function diminuir () {
        var tamanho_da_fonte = getTamanhoFonte;
        if(!localStorage.getItem('trpTamanhoFonte')){
            tamanho_da_fonte = 36;
            tamanho_da_fonte = tamanho_da_fonte - 3;
            localStorage.setItem('trpTamanhoFonte', (tamanho_da_fonte).toString());
            setTamanhoFonte(tamanho_da_fonte);
        }else{
            tamanho_da_fonte = tamanho_da_fonte - 3;
            localStorage.setItem('trpTamanhoFonte', (tamanho_da_fonte).toString());
            setTamanhoFonte(tamanho_da_fonte);
        };
        localStorage.setItem('trpTamanhoFonte', (tamanho_da_fonte).toString());
    };

    function aumentar () {
        var tamanho_da_fonte = getTamanhoFonte;
        if(!localStorage.getItem('trpTamanhoFonte')){
            tamanho_da_fonte = 36;
            tamanho_da_fonte = tamanho_da_fonte + 3;
            localStorage.setItem('trpTamanhoFonte', (tamanho_da_fonte).toString());
            setTamanhoFonte(tamanho_da_fonte);
        }else{
            tamanho_da_fonte = tamanho_da_fonte + 3;
            localStorage.setItem('trpTamanhoFonte', (tamanho_da_fonte).toString());
            setTamanhoFonte(tamanho_da_fonte);
        };
        localStorage.setItem('trpTamanhoFonte', (tamanho_da_fonte).toString());
    };

    function imprimir() {
        var pai = document.querySelector("#relatorio").parentNode;
        var relatorio = pai.querySelector("#container-conteudo-relatorio").innerHTML;
        // CRIA UM OBJETO WINDOW
        var estilo = "<style> img, a { max-width: 70%; max-height: 25%; } li { list-style: decimal; margin: 0px 0px 0px 60px; } h1{ font-size: 30px; font-weight: 800; line-height: 2rem; margin: 20px 0px 20px 20px; text-align: left; } h2{ font-size: 20px; font-weight: 800; line-height: 2rem; line-height: 2rem; margin: 10px 0px 10px 20px; text-align: left; } h3{ font-size: 30px; font-weight: 800; line-height: 2rem; margin: 20px 0px 20px 20px; text-align: center } h4{ font-size: 20px; font-weight: 800; line-height: 2rem; margin: 10px 0px 10px 20px; text-align: center; } h5{ font-size: 16px; font-weight: 400; line-height: 2rem; margin: 0px 0px 0px 40px; text-align: center; } h6{ font-size: 16px; font-weight: 400; margin-left: 20px; line-height: 1.5rem; margin: 0px 0px 0px 40px; margin: 0% 1% 0% 55%; } p { font-size: 16px; font-weight: 400; margin-left: 20px; line-height: 1.5rem; margin: 0px 0px 0px 40px; width:100%; } ul{ li{ p{ margin: 0px 50px 0px 40px; input{ margin: 0px 10px 0px -25px; } } } } blockquote{ margin: 20px 20px 20px 20px; }. table-container{ height: 400px; width: 400px; margin: 0 auto; table{ width: auto; border-collapse: collapse; th, td{ border: 1px solid #ccc; padding: 5px; } th{ font-weight: 400; } } }</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>'); 
        win.document.write(estilo);                                    
        win.document.write('</head>');                                   
        win.document.write('<body>');
        win.document.write(relatorio);                         
        win.document.write('</body></html>');                               
        win.print();
        win.close();                                                         
    }

    function salvar_codigo(){
        var filename = 'código';
        var relatorio = document.getElementById("bloco-notas").value;
        var estilo = "<style></style>";
        var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title> ";
        preHtml = preHtml+estilo+" </head><body>";
        var postHtml = "</body></html>";
        var html = preHtml+relatorio+postHtml;
        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });
        
        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        // Specify file name
        filename = filename?filename+'.doc':'document.doc';
        // Create download link element
        var downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        
        if(navigator.msSaveOrOpenBlob ){
            navigator.msSaveOrOpenBlob(blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = url;
            // Setting the file name
            downloadLink.download = filename;
            //triggering the function
            downloadLink.click();
        }
        document.body.removeChild(downloadLink);
    }

    function salvar(){
        var filename = 'relatorio';
        var pai = document.querySelector("#relatorio").parentNode;
        var relatorio = pai.querySelector("#container-conteudo-relatorio").innerHTML;
        var estilo = "<style> img, a { max-width: 70%; max-height: 25%; } li { list-style: decimal; margin: 0px 0px 0px 60px; } h1{ font-size: 30px; font-weight: 800; line-height: 2rem; margin: 20px 0px 20px 20px; text-align: left; } h2{ font-size: 20px; font-weight: 800; line-height: 2rem; line-height: 2rem; margin: 10px 0px 10px 20px; text-align: left; } h3{ font-size: 30px; font-weight: 800; line-height: 2rem; margin: 20px 0px 20px 20px; text-align: center } h4{ font-size: 20px; font-weight: 800; line-height: 2rem; margin: 10px 0px 10px 20px; text-align: center; } h5{ font-size: 16px; font-weight: 400; line-height: 2rem; margin: 0px 0px 0px 40px; text-align: center; } h6{ font-size: 16px; font-weight: 400; margin-left: 20px; line-height: 1.5rem; margin: 0px 0px 0px 850px; } p { font-size: 16px; font-weight: 400; margin-left: 20px; line-height: 1.5rem; margin: 0px 0px 0px 40px; width:100%; } ul{ li{ p{ margin: 0px 50px 0px 40px; input{ margin: 0px 10px 0px -25px; } } } } blockquote{ margin: 20px 20px 20px 20px; }. table-container{ height: 400px; width: 400px; margin: 0 auto; table{ width: auto; border-collapse: collapse; th, td{ border: 1px solid #ccc; padding: 5px; } th{ font-weight: 400; } } }</style>";
        var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title> ";
        preHtml = preHtml+estilo+" </head><body>";
        var postHtml = "</body></html>";
        var html = preHtml+relatorio+postHtml;
        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });
        
        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        // Specify file name
        filename = filename?filename+'.doc':'document.doc';
        // Create download link element
        var downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        
        if(navigator.msSaveOrOpenBlob ){
            navigator.msSaveOrOpenBlob(blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = url;
            // Setting the file name
            downloadLink.download = filename;
            //triggering the function
            downloadLink.click();
        }
        document.body.removeChild(downloadLink);
    }

    return(
        <EstiloPaginaRelatorioDinamico id='container-pagina-relatorio-dinamico'>
            <div className='formulario'>
                <Grupo nome="Botões">
                        <GrupoBotoes nome="Zoom">
                            <FaMinusCircle className='botao' title='Diminuir a Fonte' id='btn-diminui' onClick={diminuir}/>
                            <FaPlusCircle  className='botao' title='Aumentar a Fonte' id='btn-aumenta' onClick={aumentar}/>
                        </GrupoBotoes> 
                </Grupo>
                <label className="etiquetacodigo">Código:</label>
                <div id="codigo">
                    <textarea id='bloco-notas' style={{fontSize:`${getTamanhoFonte}px`}}></textarea>
                    <FaCopy className='botao' id="btn-copiar-codigo" title="Copiar o Código" onClick={copia_codigo}/>
                    <IoMdSave className='botao' id="btn-salvar-codigo" title="Salvar o Código" onClick={salvar_codigo}></IoMdSave>
                </div>
                <div className='area-botao-script'>
                    <button id='btn-cria-formulario' onClick={atualiza_formulario}>Atualiza Formulario</button>
                </div>
                <div className='area-do-formulario'>
                    <label className="etiquetaformulario">Formulário:</label>
                    <form id='formulario-relatorio-dinamico'>
                        <table id='tb-formulario'>
                        </table>
                    </form>
                </div>
                <div className='area-botao-relatorio'>
                    <button id='btn-cria-relatorio' onClick={atualiza_relatorio}>Atualiza Relatório</button>
                </div>
                <div className='area-do-relatorio'>
                    <label className="etiquetarelatorio">Relatório:</label>
                    <div id='relatorio'>
                        <ConteudoRelatorio texto={getRelatorio}/> 
                        <ImPrinter className='botao' id="btn-imprimir" title="Imprimir o Relatório" onClick={imprimir}></ImPrinter>
                        <IoMdSave className='botao' id="btn-salvar-relatorio" title="Salvar o Relatório" onClick={salvar}></IoMdSave>
                    </div>
                </div> 
                <Tags lista={["criar seu relatório dinâmico personalizado", "criar campos do formulário dinâmicamente", "estilo markdown"]}/>
            </div>
        </EstiloPaginaRelatorioDinamico>
    );
};

