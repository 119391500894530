import { tratamentoNomeCampo } from '../../js/convesoes';
import styled from 'styled-components';
import {DM_margemGrupo,tamanho_fonte} from '../../styles/variaveis'



const Estilo = styled.fieldset`

    /* text-align:left; */
    max-width: 300px;
    background-color: ${({theme}) => theme.tmCorpo};
    color:${({theme}) => theme.tmEntradaDados};
    border-radius: 10px;
    border:none;
    /* margin: ${DM_margemGrupo}; */
    margin: 5px 5px 20px 15px;
    padding: 0px 0px 0px 0px;
    display: inline-block;
    /* background-color: #1732C4;  */

    
    .tituloGrupo {
        background-color:${({theme}) => theme.tmTituloGrupo};
        border-radius: 10px 10px 0px 0px;
        padding: 10px 10px;
        /* background-color: #BAD884;  */
        margin: 0px 0px 0px 0px;

        .desc-titulo{
            color:${({theme}) =>theme.tmTituloGrupoFonte};
            font-size: ${tamanho_fonte/2}px;
            margin: 10px 0px 0px 10px;
            width: 100%;
        }
    }

    .conteudoGrupo {
        background-color:${({theme}) => theme.tmGrupo};
        border-radius: 0px 0px 10px 10px;
        padding-top: 10px;
        display: table;
        width: 100%;
    }

    
`;

interface GrupoProps {
    nome:string;
    children?:React.ReactNode;
}

function Grupo({ nome, children }: GrupoProps) {
    return (
        <Estilo className="grupo" id={`grp-${tratamentoNomeCampo(nome)}`}>
            <div className='tituloGrupo'>
                <p className='desc-titulo'><b>{nome}</b></p>
            </div>
            <div className='conteudoGrupo'>
                {children}
            </div>
        </Estilo>
    );
}

export default Grupo;