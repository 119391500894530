import logoAzul from "../imagens/blocoazul.png";
import logoLaranja from "../imagens/blocolaranja.png";
import logoVerde from "../imagens/blocoverde.png";
import logoLilas from "../imagens//blocolilas.png";

// Tema Azul 
export const azul_logo = logoAzul;

export const azul_Pagina_Titulo = "#334257";
export const azul_Pagina_SubTitulo = "#334257";
export const azul_Pagina_Fundo = "#C5C4C2";

export const azul_ajuda = "#B8E8FC";

export const azul_EntradaDados = "#FFFFFF";
export const azul_EntradaDadosFonte = "#000000";

export const azul_CabecalhoFundo = "#277BC0"; 
export const azul_CabecalhoFonte = "#2B3752";

export const azul_SubTituloFundo = "#2B3752"; 
export const azul_SubTituloFonte = "#AAC4FF";
export const azul_SubTituloHover = "#40DFEF";

export const azul_MenuFundo = "#AAC4FF"; 
export const azul_MenuFonte = "#2B3752";

export const azul_RodapeFundo = "#BAD7E9";
export const azul_RodapeFonte = "#2B3752";

export const azul_Corpo = "#FFFFFF";
export const azul_Formulario = "#DBC9A8";  

export const azul_Grupo = "#BFEAF5";
export const azul_TituloGrupo = "#4B6587";
export const azul_TituloGrupoFonte = "#FFFFFF";

export const azul_GrupoBotoes = "#BCCEF8";
export const azul_TituloGrupoBotoesFonte = "#000000";

export const azul_SubGrupo = "#F59A11";
export const azul_Radio = "#F59A11";

export const azul_Menu = "#F55911";        
export const azul_SubMenu = "#F59A11";

export const azul_Botao = "#2B3467"; 
export const azul_BotaoFont = "#C0DEFF";    
export const azul_BotaoHover = "#2192FF";  
export const azul_BotaoHoverFont = "#2B3752"; 
export const azul_BotaoActive = "#5F9DF7"; 
 
export const azul_Erro = "#144272";       
export const azul_Ok = "#91DDA4";            
        
export const azul_Alerta = "#c95c03";

export const azul_Informativo = "#9DBCE0"; 
export const azul_Link = "#0a3185";
export const azul_Imagem = "#9b210c";

// Tema Laranja

export const laranja_logo = logoLaranja;

export const laranja_Pagina_Titulo = "#5C0D02";
export const laranja_Pagina_SubTitulo = "#5C0D02";
export const laranja_Pagina_Fundo = "#C5C4C2";

export const laranja_ajuda = "#FFDCA9";

export const laranja_EntradaDados = "#FFFFFF";
export const laranja_EntradaDadosFonte = "#000000";

export const laranja_CabecalhoFundo = "#AC4B1C"; 
export const laranja_CabecalhoFonte = "#DAB784";

export const laranja_SubTituloFundo = "#5C0D02"; 
export const laranja_SubTituloFonte = "#FFD57E";
export const laranja_SubTituloHover = "#FF6E31";

export const laranja_MenuFundo = "#FFF4CF"; 
export const laranja_MenuFonte = "#AC4B1C";

export const laranja_RodapeFundo = "#FFCB42";
export const laranja_RodapeFonte = "#5C0D02";

export const laranja_Corpo = "#FFFFFF";
export const laranja_Formulario = "#DBC9A8";  

export const laranja_Grupo = "#F3F0D7"; 
export const laranja_TituloGrupo = "#D8B384";
export const laranja_TituloGrupoFonte = "#000000";

export const laranja_GrupoBotoes = "#C8C6C6";
export const laranja_TituloGrupoBotoesFonte = "#000000";

export const laranja_SubGrupo = "#F59A11";
export const laranja_Radio = "#F59A11";

export const laranja_Menu = "#F55911";        
export const laranja_SubMenu = "#F59A11";
  
export const laranja_Botao = "#5C0D02";  
export const laranja_BotaoFont = "#E6D2AA";     
export const laranja_BotaoHover = "#F57328";  
export const laranja_BotaoHoverFont = "#42032C";  
export const laranja_BotaoActive = "#C95C03"; 
 
export const laranja_Erro = "#144272";       
export const laranja_Ok = "#91DDA4";            
        
export const laranja_Alerta = "#c95c03";

export const laranja_Informativo = "#9DBCE0"; 
export const laranja_Link = "#0a3185";
export const laranja_Imagem = "#9b210c";

// Tema Verde

export const verde_logo = logoVerde;

export const verde_Pagina_Titulo = "#285430";
export const verde_Pagina_SubTitulo = "#285430";
export const verde_Pagina_Fundo = "#C5C4C2";

export const verde_ajuda = "#C8FFD4";

export const verde_EntradaDados = "#FFFFFF";
export const verde_EntradaDadosFonte = "#000000";

export const verde_CabecalhoFundo = "#5BB318"; 
export const verde_CabecalhoFonte = "#1A4D2E";

export const verde_SubTituloFundo = "#1A4D2E"; 
export const verde_SubTituloFonte = "#E6E5A3";
export const verde_SubTituloHover = "#9CFF2E";

export const verde_MenuFundo = "#C6EBC5"; 
export const verde_MenuFonte = "#005555";

export const verde_RodapeFundo = "#D4F6CC";
export const verde_RodapeFonte = "#557153";

export const verde_Corpo = "#FFFFFF";
export const verde_Formulario = "#DBC9A8";  

export const verde_Grupo = "#B4CDE6"; 
export const verde_TituloGrupo = "#628E90";
export const verde_TituloGrupoFonte = "#000000";

export const verde_GrupoBotoes = "#DEF5E5";
export const verde_TituloGrupoBotoesFonte = "#000000";

export const verde_SubGrupo = "#F59A11";
export const verde_Radio = "#F59A11";

export const verde_Menu = "#F55911";        
export const verde_SubMenu = "#F59A11";
  
export const verde_Botao = "#285430";     
export const verde_BotaoFont = "#E5D9B6";  
export const verde_BotaoHover = "#5BB318";  
export const verde_BotaoHoverFont = "#285430";  
export const verde_BotaoActive = "#DEF5E5"; 
 
export const verde_Erro = "#144272";       
export const verde_Ok = "#91DDA4";            
        
export const verde_Alerta = "#c95c03";

export const verde_Informativo = "#9DBCE0"; 
export const verde_Link = "#0a3185";
export const verde_Imagem = "#9b210c";


/* Lilas (Purple)*/

export const lilas_logo = logoLilas;

export const lilas_Pagina_Titulo = "#810CA8";
export const lilas_Pagina_SubTitulo = "#810CA8";
export const lilas_Pagina_Fundo = "#C5C4C2";

export const lilas_ajuda = "#FFE6F7";

export const lilas_EntradaDados = "#FFFFFF";
export const lilas_EntradaDadosFonte = "#000000";

export const lilas_CabecalhoFundo = "#6F1AB6"; 
export const lilas_CabecalhoFonte = "#E5B8F4";

export const lilas_SubTituloFundo = "#A555EC"; 
export const lilas_SubTituloFonte = "#2D033B";
export const lilas_SubTituloHover = "#E5B8F4";

export const lilas_MenuFundo = "#D09CFA"; 
export const lilas_MenuFonte = "#810CA8";

export const lilas_RodapeFundo = "#B1AFFF";
export const lilas_RodapeFonte = "#810CA8";

export const lilas_Corpo = "#FFFFFF";
export const lilas_Formulario = "#DBC9A8";  

export const lilas_Grupo = "#FFE6F7"; 
export const lilas_TituloGrupo = "#7858A6";
export const lilas_TituloGrupoFonte = "#FFABE1";

export const lilas_GrupoBotoes = "#E5B8F4";
export const lilas_TituloGrupoBotoesFonte = "#810CA8";

export const lilas_SubGrupo = "#F59A11";
export const lilas_Radio = "#810CA8";

export const lilas_Menu = "#F55911";        
export const lilas_SubMenu = "#F59A11";
  
export const lilas_Botao = "#8758FF";     
export const lilas_BotaoFont = "#FDEBF7";  
export const lilas_BotaoHover = "#C147E9";  
export const lilas_BotaoHoverFont = "#D09CFA";  
export const lilas_BotaoActive = "#DEF5E5"; 
 
export const lilas_Erro = "#144272";       
export const lilas_Ok = "#91DDA4";            
        
export const lilas_Alerta = "#c95c03";

export const lilas_Informativo = "#9DBCE0"; 
export const lilas_Link = "#0a3185";
export const lilas_Imagem = "#D58BDD";

/* Divertido (Kids)*/

export const divertido_logo = logoVerde;

export const divertido_Pagina_Titulo = "#FFBF00";
export const divertido_Pagina_SubTitulo = "#FFBF00";
export const divertido_Pagina_Fundo = "#C5C4C2";

export const divertido_ajuda = "#C8FFD4";

export const divertido_EntradaDados = "#FFFFFF";
export const divertido_EntradaDadosFonte = "#000000";

export const divertido_CabecalhoFundo = "#FF74B1"; 
export const divertido_CabecalhoFonte = "#850000";

export const divertido_SubTituloFundo = "#7D8F69"; 
export const divertido_SubTituloFonte = "#E6E5A3";
export const divertido_SubTituloHover = "#9CFF2E";

export const divertido_MenuFundo = "#E6E5A3"; 
export const divertido_MenuFonte = "#005555";

export const divertido_RodapeFundo = "#E6E5A3";
export const divertido_RodapeFonte = "#557153";

export const divertido_Corpo = "#FFFFFF";
export const divertido_Formulario = "#DBC9A8";  

export const divertido_Grupo = "#B4CDE6"; 
export const divertido_TituloGrupo = "#628E90";
export const divertido_TituloGrupoFonte = "#000000";

export const divertido_GrupoBotoes = "#DEF5E5";
export const divertido_TituloGrupoBotoesFonte = "#000000";

export const divertido_SubGrupo = "#F59A11";
export const divertido_Radio = "#F59A11";

export const divertido_Menu = "#F55911";        
export const divertido_SubMenu = "#F59A11";
  
export const divertido_Botao = "#2C3639";     
export const divertido_BotaoFont = "#E5D9B6";  
export const divertido_BotaoHover = "#285430";  
export const divertido_BotaoHoverFont = "#285430";  
export const divertido_BotaoActive = "#DEF5E5"; 
 
export const divertido_Erro = "#144272";       
export const divertido_Ok = "#91DDA4";            
        
export const divertido_Alerta = "#c95c03";

export const divertido_Informativo = "#9DBCE0"; 
export const divertido_Link = "#0a3185";
export const divertido_Imagem = "#9b210c";



// Variaveis BreakPoints
export const BK_mobile = "768px";
export const BK_desktop_xsm = "980px";
export const BK_desktop_sm = "1080px";
export const BK_desktop_md = "1280px";

// Variaveis Dimencionais

export const DM_larguraCampo = "25px";
export const DM_margemCampo = "10px";
export const DM_margemGrupo = "20px";

export const padding_horizontal_mobile = "100px";
export const padding_horizontal_sm = "150px";
export const padding_horizontal_md = "200px";
export const padding_horizontal_nn = "50px";

export const tamanho_fonte = 26;
export const tamanho_fonte_bloco = 24;
export const tamanho_icone = 16;


/* Rosa (Pink), Lilas (Purple), Divertido (Kids) e Escuro (Black e Grey) */





