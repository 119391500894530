import { tratamentoNomeCampo } from '../../js/convesoes';
import styled from 'styled-components';

const EstiloGrupoBotoes = styled.fieldset`
    background-color: ${({theme}) => theme.tmGrupoBotoes};
    border-radius: 10px;
    margin: 10px;
    display:table;
    justify-content:center;
    align-items:center;
    float: left;
    max-width: 200px;

    legend{
        font-weight: 600;
        color: ${({theme}) => theme.tmTituloGrupoBotoesFonte};
        background-color: transparent;
    }
`;

interface GrupoBotoesProps {
    nome:string;
    children?:React.ReactNode;
}

function GrupoBotoes({ nome, children }: GrupoBotoesProps) {
    return (
        <EstiloGrupoBotoes className="grupobotoes" id={`grpb-${tratamentoNomeCampo(nome)}`}>
            <legend className='legGrupoBotoes'>{nome}</legend>
            {children}
        </EstiloGrupoBotoes>
    );
}

export default GrupoBotoes;