
import styled from 'styled-components';
import {BsDiagram3,BsMailbox} from 'react-icons/bs';
import { SlSocialInstagram} from 'react-icons/sl';
import { BsFillShareFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";

const EstiloRodape = styled.footer`


@media (min-width: 320px) and (max-width: 480px) {

    background-color: ${({theme}) => theme.tmRodapeFundo};
    display: table; 
    width: fit-content;
    margin: 0px 0px;
    padding: 0px 0px 0px 0px;

    .icone{
        width: 35px;
        height: 35px;
        margin: 0px 50px 0px 50px;  
        padding: 0px 10px 0px 0px; 
        color: ${({theme}) => theme.tmRodapeFonte};
    } 

    .grid-container {
        display: grid;
        padding: 10px 10px;
        margin: 0px 0px 0px 0px;
        
        .redessociais {
            float: left;
            width: 100%;
            margin: 30px auto 30px auto;
            padding: 30px auto 30px auto;
        }

        .grid-item {
            padding: 20px;
            font-size: 30px;
            text-align: center;
            display: table;

            p{
                float: left;
                font-size: 16px;
                width: 55%;
                margin: 0px 25px 0px 25px;
                padding: 0px 0px;
                line-height: 36px;
            }

            .mapadosite {
                float: left; 
                width: 100%;  
                margin: 10px 10px 10px 10px;
                padding: 20px 0px 0px 0px;
                font-size: 16px;
                display: table;

                #imgOrganograma {
                    float: left; 
                    width: 40px;
                    height: 40px;
                    margin: 0px 0px 0px 0px;
                    padding: 0px 0px;

                }

                ul {
                    width: 100%; 
                    margin: 10px auto 10px auto;
                    display: table;
                }
            
                li {
                    line-height: 36px;
                    display: inline;
                    padding: 0 15px 0px 15px;
                    margin: 0px 0px;

                } 
            }
        }
    }
}

@media (min-width: 481px) {

    background-color: ${({theme}) => theme.tmRodapeFundo};
    display: table;
    width: 100%; 
    padding: 0px 0px 0px 0px;
 
    .icone{
        width: 30px;
        height: 30px;
        margin: 0px 50px 0px 50px;  
        padding: 0px 10px 0px 0px; 
        color: ${({theme}) => theme.tmRodapeFonte};
    } 

    .grid-container {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        padding: 10px 10px;
        margin: 20px 20px;
        
        .grid-item {
            padding: 20px;
            font-size: 30px;
            text-align: center;
            
            p{
                float: left;
                font-size: 14px;
                width: 55%;
                margin: 0px 0px 0px 25px;
                padding: 0px 0px;
                line-height: 36px;
            }

            .mapadosite {
                float: left; 
                width: 100%;  
                margin: 10px auto 10px auto;
                padding: 20px auto 0px auto;
                font-size: 14px;
                display: table;

                #imgOrganograma {
                    float: left; 
                    width: 40px;
                    height: 40px;
                    margin: 0px 0px 0px 0px;
                }
                
                ul {
                    width: 80%; 
                    margin: 10px auto 10px auto;
                    display: table;
                }
            
                li {
                    line-height: 36px;
                    display: inline;
                    padding: 0 15px 0px 15px;
                } 

            }

            .redessociais {
                float: left;
                width: 100%;
                margin: 30px auto 30px auto;
                padding: 30px auto 30px auto;
            }

            #imgAntonino{
                margin: 0px 100px 0px 80px; 
            }
        
            #nomeAntonino{
                margin: 0px 20px 0px 20px; 
            }

            #imgCompartilhar {
                float: left; 
                width: 40px;
                height: 40px;
                margin: 20px 0px 0px 0px;
            }

        }

    }

}

`;

const Rodape  = () => {
    return(
        <EstiloRodape id='rodape'>
            <div className="grid-container">
                <div className="grid-item">
                    <div className="mapadosite">
                        <a  href="https://www.linkedin.com/in/antonino-marques-jares-b447a734/" title='Linkedin de Antonino'>
                            <FaUserTie className="icone"/>
                        </a>
                        <br></br>
                        <ul>
                            <li>Desenvolvedor</li>
                        </ul>
                    </div>
                </div>
                <div className="grid-item">
                    <BsDiagram3 className="icone" id="imgOrganograma"/>
                    <div className="mapadosite">
                        <ul>
                        <li>Home</li>
                        <li>Grupo</li>
                        <li>Blog</li>
                        <li>Editor de Texto</li>
                        <li>Relatórios Dinâmicos</li>
                        </ul>
                    </div>
                </div>

                <div className="grid-item">
                    <div className="redessociais">
                        <a href="mailto:trampoac@gmail.com?subject=Assunto referente ao site Área de Trampo" title='E-mail para contato'>
                            <BsMailbox className="icone" id="imgEmail"/>
                        </a>
                        <a href="http://instagram.com/areadetrampo" title='Instagram do site Área de Trampo'>
                            <SlSocialInstagram className="icone" id="imgInstagram"/>
                        </a>
                    </div>
                </div>

                <div className="grid-item">
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.areadetrampo.com.br/" title='Compartilhe com seus amigos no Facebook'>
                        <BsFillShareFill className="icone" id="imgCompartilhar"/>
                    </a>
                </div>
            </div>
        </EstiloRodape>
    );
};

export default Rodape;