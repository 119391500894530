
import styled from 'styled-components';
import {tamanho_fonte,tamanho_icone} from '../../styles/variaveis'
import {IoSettings} from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const EstiloSubTitulo = styled.div`
    width: 100%;
    background-color: ${({theme}) => theme.tmSubTituloFundo};
    margin: 0px 0px 20px 0px;
    padding: 0px 0px;
    float:left;
    display: table;

    h2{
    
        margin: 30px 20px 20px 65px;
        padding: 0px 0px 0px 0px;
        color: ${({theme}) => theme.tmSubTituloFonte};
        font-size: ${tamanho_fonte/1.5}px;
        background-color: ${({theme}) => theme.tmSubTituloFundo};
        float: left;
    }

    #configuracao{
        font-size: ${tamanho_fonte}px;
        color: ${({theme}) => theme.tmSubTituloFonte};
        padding: 0px 0px 0px 0px;
        margin: 30px 20px 20px 20px;
        float: right;

        &:hover{
                color:${({theme}) => theme.tmSubTituloHover};
            }
    }

    #login, #ajuda{
        width: ${tamanho_icone}px;
        height: ${tamanho_icone}px;
        color: ${({theme}) => theme.tmSubTituloFonte};
        padding: 0px 0px 0px 0px;
        margin: 30px 20px 20px 20px;
        float: right;

        &:hover{
                color:${({theme}) => theme.tmSubTituloHover};
            }
    }`;

interface SubTituloProps {
    texto:string;
}

const SubTitulo = ({texto}:SubTituloProps) => {
    const navigate = useNavigate();

    function irParaConfiguracao (){
        navigate('/configuracao');
    };

    return(
        <>
        <EstiloSubTitulo id="subtitulo">
            <IoSettings id="configuracao" title='Configurações' onClick={irParaConfiguracao}/>
            <h2 id='h2-subtitulo'>{texto}</h2>
        </EstiloSubTitulo>
        <div id='publicidade-bloco-adsense-horizontal'>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4811375496748926"
            crossOrigin="anonymous"></script>
            {/* bloco adsense horizontal */}
            <ins className="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-4811375496748926"
                data-ad-slot="6632733634"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </div>
        </>
    );
};

export default SubTitulo;