import React from "react";
import styled from 'styled-components';
import SubTitulo from '../SubTitulo/index';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Tags from '../Tags';


const EstiloConteudoPagina = styled.div`
    background-color: ${({theme}) => theme.tmCorpo};

    float: left;
    padding: 20px 20px 30px 20px;
    margin: 0px 30px 30px 30px;
    display: table; 

    @media (min-width: 320px) and (max-width: 480px) {
        img{
            max-width: 70%;
            max-height: 25%;
        } 

        .table-container{
            width: 70%;
            margin: 0 auto;

            table{
                width: auto;
                border-collapse: collapse;
        
                th, td{
                border: 1px solid #ccc;
                padding: 5px;
                }
        
                th{
                    font-weight: 400;
                }
            }
        }
    }

    @media (min-width: 481px){
        img{
            max-width: 25%;
            max-height: 25%;
        } 

        .table-container{
            width: 90%;
            margin: 0 auto;

            table{
                width: auto;
                border-collapse: collapse;
        
                th, td{
                border: 1px solid #ccc;
                padding: 5px;
                }
        
                th{
                    font-weight: 400;
                }
            }
        }
    }

    

   li {
        list-style: decimal;
        margin: 0px 0px 0px 60px;
    } 

    h1{
        font-size: 30px;
        font-weight: 800;
        line-height: 2rem;
        margin: 20px 0px 20px 20px;
        text-align: left;
    }

    h2{
        font-size: 20px;
        font-weight: 800;
        line-height: 2rem;
        line-height: 2rem;
        margin: 10px 0px 10px 20px;
        text-align: left;
    }

    h3{
        font-size: 30px;
        font-weight: 800;
        line-height: 2rem;
        margin: 20px 0px 20px 20px;
        text-align: center
    }

    h4{
        font-size: 20px;
        font-weight: 800;
        line-height: 2rem;
        margin: 10px 0px 10px 20px;
        text-align: center;
    }

    h5{
        color: ${({theme}) => theme.tmPaginaTitulo};
        font-size: 16px;
        font-weight: 400;
        line-height: 2rem;
        margin: 0px 0px 0px 40px;
        text-align: center;
    }

    h6{
        font-size: 16px;
        font-weight: 400; 
        margin-left: 20px;
        line-height: 1.5rem;
        margin: 0px 0px 0px 40px;
        margin: 0% 1% 0% 55%;
    }

    p {
        font-size: 16px;
        font-weight: 400; 
        margin-left: 20px;
        line-height: 1.5rem;
        margin: 0px 0px 0px 40px;
        width:100%;
    }

    ul{
        li{
            line-height: 1.5rem;
            p{
                margin: 0px 50px 0px 40px;
                input{
                    margin: 0px 10px 0px -25px;
                }
            }

            a {
                text-decoration: none;
            }
        }
    }


    blockquote{
        margin: 20px 20px 20px 20px;
    } 

    

    .table-container{
        height: 400px;
        width: 400px;
        margin: 0 auto;

        table{
            width: auto;
            border-collapse: collapse;
            td, th{
                font-weight: 400;
                border: 1px solid #ccc;
                padding: 40px 40px;
                margin: 5px 5px;               
            }
        }
    }
`;



interface ConteudoPaginaProps {
    titulo:string;
    texto:string;
    tags:string[];
}


const ConteudoPagina = ({titulo,texto,tags}:ConteudoPaginaProps) => {
    
    
    return(
        <>
            <SubTitulo texto={titulo}/>
            <EstiloConteudoPagina id='container-conteudo-pagina'>
                <Markdown children={texto} remarkPlugins={[remarkGfm]}/>
            </EstiloConteudoPagina>
            <Tags lista={tags}/>
        </>
    );
};

export default ConteudoPagina;