const ExpReg = {

    QUEBRAS_DE_LINHAS:[/(\r\n|\n|\r)/gm," "],
    ESPACOS_EM_DUPLICIDADE:[/( ){2,}/g," "],
    NUMERO : [/(Nº|número|Número|NÚMERO)/gi,"Nº"],
    ARTIGO : [/ artigo | Artigo | ARTIGO | Art. /gi," art. "],
    CONSTITUICAO_FEDERAL : [/constituição federal|Constituição Federal|CONSTITUÇÃO FEDERAL/gi,"CF"],
    EMENDA_CONSTITUCIONAL : [/emenda constitucional|Emenda Constitucional|EMENDA CONSTITUCIONAL/gi,"EC"],
    LEI_COMPLEMENTAR_ESTADUAL : [/lei complementar estadual|Lei Complementar Estadual|LEI COMPLEMENTAR ESTADUAL/gi,"LCE"],
    LEI_COMPLEMENTAR_FEDERAL : [/lei complementar federal|Lei Complementar Federal|LEI COMPLEMENTAR FEDERAL/gi,"LCF"],
    RESOLUCAO : [/ resolução | Resolução | RESOLUÇÃO /gi," Resolução "],
    COMBINADO_COM : [/combinado com|Combinado Com|COMBINADO COM/gi,"c/c"],
    COMPANHIA : [/ companhia | Companhia | COMPANHIA /gi," Cia. "],
    CODIGO_PENAL : [/código penal|CÓDIGO PENAL|Código Penal/gi,"CP"],
    CODIGO_PROCESSO_CIVIL : [/código de processo civil|CÓDIGO DE PROCESSO CIVIL|Código de Processo Civil/gi,"CPC"],
    CODIGO_DE_PROCESSO_PENAL : [/código de processo penal|CÓDIGO DE PROCESSO PENAL|Código de Processo Penal/gi,"CPP"],
    DIARIO_OFICIAL_DO_ESTADO : [/diário oficial do estado|DIÁRIO OFICIAL DO ESTADO|Diário Oficial do Estado/gi,"DOE"],
    DIARIO_OFICIAL_DA_UNIAO : [/ diário oficial da união| DIÁRIO OFICIAL DA UNIÃO| Diário Oficial da União/gi,"DOU"],
    DOUTOR : [/ doutor| DOUTOR| Doutor/gi," Dr."],
    MINISTERIO_PUBLICO : [/ministério público|MINISTÉRIO PÚBLICO|Ministério Público/gi,"MP"],
    SOCIEDADE_ANONIMA : [/sociedade anônima|SOCIEDADE ANÔNIMA|Sociedade Anônima/gi,"S/A"],

    NUMERO_PROC_ELETRONICO : [/NÚMERO NO PROCESSO ELETRÔNICO: ([0-9][0-9][0-9][0-9][0-9][0-9])\/([0-9][0-9][0-9][0-9]) /gi,'PROC. ELETRÔNICO ($1-$2) '],

    JANEIRO : [/ de janeiro de | de Janeiro de | DE JANEIRO DE | de JANEIRO de /gi,"/01/"],
    FEVEREIRO : [/ de fevereiro de | de Fevereiro de | DE FEVEREIRO DE | de FEVEREIRO de /gi,"/02/"],
    MARCO : [/ de março de | de Março de | DE MARÇO DE | de MARÇO de /gi,"/03/"],
    ABRIL : [/ de abril de | de Abril de | DE ABRIL DE | de ABRIL de /gi,"/04/"],
    MAIO : [/ de maio de | de Maio de | DE MAIO DE | de MAIO de /gi,"/05/"],
    JUNHO : [/ de junho de | de Junho de | DE JUNHO DE | de JUNHO de /gi,"/06/"],
    JULHO : [/ de julho de | de Julho de | DE JULHO DE | de JULHO de /gi,"/07/"],
    AGOSTO : [/ de agosto de | de Agosto de | DE AGOSTO DE | de AGOSTO de /gi,"/07/"],
    SETEMBRO : [/ de setembro de | de Setembro de | DE SETEMBRO DE | de SETEMBRO de /gi,"/09/"],
    OUTUBRO : [/ de outubro de | de Outubro de | DE OUTUBRO DE | de OUTUBRO de /gi,"/10/"],
    NOVEMBRO : [/ de novembro de | de Novembro de | DE NOVEMBRO DE | de NOVEMBRO de /gi,"/11/"],
    DEZEMBRO : [/ de dezembro de | de Dezembro de | DE DEZEMBRO DE | de DEZEMBRO de /gi,"/12/"],

    DAFO_AP_APOSENTADORIA : [/Aposentadoria da servidora |Aposentadoria do servidor |Aposentadoria do \(a\) (servidor|Servidor) \(a\) |Aposentadoria do\(a\) (servidor|Servidor)\(a\)/gi,"\n"],
    DAFO_AP_MATRICULA : [/(, | )(matrícula|MATRÍCULA)/gi,"\nmatrícula"],
    DAFO_AP_CARGO : [/, CPF(.*)no cargo (de |de)?/gi,"\n\n"],
    DAFO_AP_QUADRO_DE_PESSOAL : [/(, do Quadro de Pessoal do Estado )(da |do )/gi,"\n"],
    DAFO_AP_MODALIDADE : [/(, | )(na modalidade )/gi,"\n"],
    DAFO_AP_NOS_TERMOS : [/(, | )(nos termos do )/gi,"\n\n"],
    DAFO_AP_CONFORME : [/(, | )(conforme )/gi,"\n\n"],
    DAFO_AP_PUBLICADA_NO : [/(, | )(publicada no )/gi,"\n"],
    DAFO_AP_PELOS_SEUS : [/(, | )(e pelos seus )/gi,"\n\n"],
    DAFO_AP_DIAS : [/dias(,| )(.*)([0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9])?(.*)(R\$)/gi,"dias \nInicio \nFinal \n\nAto de fixação de proventos fl. \nR$ "],
    DAFO_AP_ENQUADRAMENTO_FINAL : [/(, | )(correspondente ao seu enquadramento final)(.|)/gi,"\nComposto por..."],

    PAUTA_TCE : [/TRIBUNAL DE CONTAS DO ESTADO DO ACRE\nSecretaria das Sessões\n/gi,""],
    PAUTA_NUMERO : [/([0-9][0-9][0-9]|[0-9][0-9]|[0-9]) . (Processo)/gi,"\n\n\nNº Pauta: $1 $2"],
    PAUTA_CONTROLE_SOCIAL : [/PAUTA DOS TRABALHOS DO(.*)\n(.*)\n(.*)\n(.*)do controle social./gi,""],
    PAUTA_PEDIDO_VISTA : [/PEDIDO DE VISTA/gi,"\n\nPEDIDO DE VISTA\n"],
    PAUTA_JULGAMENTO : [/JULGAMENTO\n/gi,"\n\n\nJULGAMENTO\n\n\n"],
    PAUTA_RESPONSAVEIS : [/(Responsável\(is\)|Responsáveis|Responsável):/gi,"\nResponsáveis:"],
    PAUTA_TCE_SECRETARIA : [/TRIBUNAL DE CONTAS DO ESTADO DO ACRE(.*)\n(.*)controle social.(.*)\n\nSecretaria das Sessões/gi,""],
    PAUTA_DUAS_OAB : [/OAB [A-Z][A-Z] ([0-9][0-9][0-9][0-9]|[0-9][0-9][0-9]) E OAB [A-Z][A-Z] ([0-9][0-9][0-9][0-9]|[0-9][0-9][0-9])/gi,""],
    PAUTA_UMA_OAB : [/OAB [A-Z][A-Z] ([0-9][0-9][0-9][0-9]|[0-9][0-9][0-9])/gi,""],
    PAUTA_PENULTIMA_LINHA : [/ Av. Ceará, 2994, Bairro 7(ª|º) BEC . Rio Branco . Acre, CEP 69.918.111 Telefone \(68\) 3025.2039 . Fonefax \(68\) 3025.2041 . Email: pres@tce.ac.gov.br ([0-9][0-9][0-9]|[0-9][0-9]|[0-9])/g,""],
    PAUTA_ULTIMA_LINHA : [/ ([0-9][0-9]|[0-9]) de (.*) de [0-9][0-9][0-9][0-9]. Cons. Ronald Polanco Ribeiro Presidente da Plenária/g,"\n\n\n"],
    PAUTA_PROCESSO : [/(Processo:)/gi,"\n$1"],
    PAUTA_REVISOR : [/ (revisor|Revisor|REVISOR)/g,'\n$1'],
    PAUTA_ENTIDADE : [/(Entidade:)/g, "\n$1"],
    PAUTA_NATUREZA : [/(Natureza:)/g, "\n$1"],
    PAUTA_OBJETO : [/(Objeto:)/g, "\n$1"],
    PAUTA_ADVOGADO : [/(Advogado\(a\):|Advogados\(as\):)/g, "\n$1"],
    PAUTA_RELATOR : [/(Relator|Relatora:)/g, "\nProc. do MPC:\n$1"],
    PAUTA_SEPARADOR : [/(Nº Pauta:)/g, "\n\n\n$1"],

    NOME_DA : [/ Da | DA /gi," da "],
    NOME_DAS : [/ Das | DAS /gi," das "],
    NOME_DE : [/ De | DE /gi," de "],
    NOME_DEL : [/ Del | DEL /gi," del "],
    NOME_DO : [/ Do | DO /gi," do "],
    NOME_DOS : [/ Dos | DOS /gi, " dos "],
    NOME_E : [/ E /gi," e "],
    ESPACO_E_PONTUACAO : [/ (,|;|\.|:|!)/gi,'$1 '],

    CSS_ESPACO_EM_BRANCO:[/ /gi,'-'],
    VARIAVEL_ESPACO_EM_BRANCO:[/ /gi,'_'],
    FORMULARIO_LETRA_A:[/á|à|ã/gi,'a'],
    FORMULARIO_LETRA_E:[/é|è/gi,'e'],
    FORMULARIO_LETRA_I:[/í|ì/gi,'i'],
    FORMULARIO_LETRA_O:[/ó|ò|ô|õ/gi,'o'],
    FORMULARIO_LETRA_U:[/ú|ù/gi,'u'],
    FORMULARIO_LETRA_C:[/ç/gi,'c'],
    FORMULARIO_CARACTERES_ESPECIAIS:[/º|ª/gi,'']
}

export default ExpReg;