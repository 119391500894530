import React, {useState} from "react";
import styled from 'styled-components';
import Grupo from '../../componentes/Grupo';
import GrupoBotoes from '../../componentes/GrupoBotoes';
import Tags from '../../componentes/Tags/index';
import ExpReg from '../../js/exp-reg';
import Tesseract  from 'tesseract.js';
import { MdVisibility } from "react-icons/md";
import { FaRocket, FaMinusCircle , FaPlusCircle, FaCheckCircle,FaUserCircle} from "react-icons/fa";
import { TbLetterCaseUpper,TbLetterCaseLower} from "react-icons/tb";
import { BsFillCalendarDateFill, BsFillQuestionCircleFill, BsInfoCircleFill} from "react-icons/bs";
import { ImHammer2} from "react-icons/im";
import { IoEnter} from "react-icons/io5";
import { tratamentoNomeCampo } from '../../js/convesoes';
import { useNavigate } from 'react-router-dom';
import { FaRegCopy } from "react-icons/fa";
import { FaRegPaste } from "react-icons/fa6";
import { FaCut } from "react-icons/fa";



const EstiloPaginaEditorTexto = styled.section`
        width: 100%;
        color: ${({theme}) => theme.corpo};
        background-color: ${({theme}) => theme.tmCorpo};
        float: left;
        display: table;
       /*  background-color: #FFEA20; */

       .formulario{
        background-color:${({theme}) => theme.tmCorpo};
        width: 80%;
        display: table;
        margin: 0px auto 0px auto;
        }

        .idioma{
            color:${({theme}) => theme.tmEntradaDadosFonte};
        }

        .botao{
            color:${({theme}) => theme.tmBotao};
            width: 30px;
            height: 30px;
            margin: 10px 10px 10px 10px;

            &:hover{
                color:${({theme}) => theme.tmBotaoHover};
            }
        }

        .botao-ajuda{
            color:${({theme}) => theme.tmBotao};
            width: 30px;
            height: 30px;
            margin: 5px 5px 5px 5px;

            &:hover{
                color:${({theme}) => theme.tmBotaoHover};
            }
        }

        .grupo{
            padding: 0px 0px 0px 0px;
        }

        p{
            color:${({theme}) => theme.tmBotao};
        }

        #bloco-notas{
            width: 80%;
            height: 200px;
            margin: 0px 5px 20px 10px;
            padding: 20px 20px 20px 20px;
            font-size: ${localStorage.getItem('trpTamanhoFonte')}px;
            resize: vertical;
            float: left;
        }

        .apagado{
            opacity: 0;
            transition: 0.5s;
        }

        .aceso{
            opacity: 100;
            transition: 0.5s;
        }

        #ajuda{
            background-color: ${({theme}) => theme.tmAjuda};
            width: 75%;
            height: auto;
            padding: 10px 10px 10px 10px;
            margin: 10px auto 0px auto;
            border-radius: 20px;
            display: none;
            .ul{
            height: 20px;
            float: none;
            display: none;
            }
        }

        #mensagem{
            background-color: ${({theme}) => theme.tmAjuda};
            width: 75%;
            height: auto;
            padding: 10px 10px 10px 10px;
            margin: 20px;
            border-radius: 20px;
            display: none;
            
        }

        #btn-ajuda{
            float: right;
            padding: 10px 10px 10px 10px;
        }

        .entrada-texto{
            border: 0px solid #000000;
            border-radius: 10px;
            font-weight:700;
            font-size:12px;
            border:none;
            color:#000000;
            float:left;
            padding: 5px;
            margin: 10px 10px 10px 10px;
            min-width:280px;
            min-height:50px;

            input{
            border: 2px solid #000000;
            border-radius: 10px;
            font-weight:700;
            font-size:12px;
            border:none;
            color:#000000;
            float:left;
            padding: 5px;
            margin: $margemCampo;
            min-width:280px;
            min-height:50px;
            }
                
                
            }

        .combobox{
            border: 0px solid #000000;
            border-radius: 10px;
            font-weight:700;
            font-size:12px;
            border:none;
            color:#000000;
            float:left;
            padding: 3px;
            margin: $margemCampo;

            max-width:200px;
            min-height:30px;  
        }

        .botao-texto{
            border: 0px solid #000000;
            border-radius: 10px;
            font-weight:700;
            font-size:16px;
            border:none;
            color:#000000;
            float:right;
            padding: 3px;
            margin: $margemCampo;
            transition: .3s;
            background-color: ${({theme}) => theme.tmBotao};
            
            &:hover {
                border: none;
                color: #000000;
                transition: .3s;
                background-color: ${({theme}) => theme.tmBotaoHover};
                cursor:pointer;
            }

            &:active {
                transform: scale(0.9);
                transition: all 0.5s;
                color: #000000;
                background-color: ${({theme}) => theme.tnBotaoActive};
            }     
        }
`;



export default function PaginaEditorTexto () {
    var [tamanhoFonte,setTamanhoFonte] = useState(parseInt(localStorage.getItem('trpTamanhoFonte')));
    const [isLoading, setIsLoading] = React.useState(false);
    const [image, setImage] = React.useState('');
    const [text, setText] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    let linguagem = 'por';
    let i = 0;
    const idiomas =[
    ["Africâner","afr"],
    ["Amárico","amh"],
    ["Árabe","ara"],
    ["Assamês","asm"],
    ["Azerbaijão","aze"],
    ["Azerbaijão - Cirílico","aze_cyrl"],
    ["Bielorrússia","bel"],
    ["Bengali","ben"],
    ["Tibetano","bod"],
    ["Bósnio","bos"],
    ["Bretão","bre"],
    ["Búlgaro","bul"],
    ["Catalão;Valenciano","cat"],
    ["Cebuano","ceb"],
    ["Tcheco","ces"],
    ["Chinês - Simplificado","chi_sim"],
    ["Chinês - Tradicional","chi_tra"],
    ["Cherokee","chr"],
    ["Corsa","cos"],
    ["Galês","cym"],
    ["Dinamarquês","dan"],
    ["Dinamarquês - Fraktur (contrib)","dan_frak"],
    ["Alemão","deu"],
    ["Alemão - Fraktur (contrib)","deu_frak"],
    ["Dzongkha","dzo"],
    ["Grego,Moderno (1453-)","ell"],
    ["Inglês","eng"],
    ["Inglês, Médio (1100-1500)","enm"],
    ["Esperanto","epo"],
    ["Módulo de detecção matemática/equação","equ"],
    ["Estoniano","est"],
    ["Euskara,Basco","eus"],
    ["Feroesa","fao"],
    ["Persa","fas"],
    ["Filipino (antigo - Tagalo)","fil"],
    ["Finlandês","fin"],
    ["Francês","fra"],
    ["Alemão - Fraktur","frk"],
    ["Francês, Médio (ca.1400-1600)","frm"],
    ["Frísia Ocidental","fry"],
    ["Ânglico Escocês","gla"],
    ["Irlandês","gle"],
    ["Galego","glg"],
    ["Grego,Antigo (até 1453) (contrib)","grc"],
    ["Gujarati","guj"],
    ["Haitiano;Crioulo Haitiano","hat"],
    ["Hebraico","heb"],
    ["Hindi","hin"],
    ["Croata","hrv"],
    ["Húngaro","hun"],
    ["Armênio","hye"],
    ["Inuktitut","iku"],
    ["Indonésio","ind"],
    ["Islandês","isl"],
    ["Italiano","ita"],
    ["Italiano - Antigo","ita_old"],
    ["Javanês","jav"],
    ["Japonês","jpn"],
    ["Canarês","kan"],
    ["Georgiano","kat"],
    ["Georgiano - Antigo","kat_old"],
    ["Cazaque","kaz"],
    ["Khmer, Cambojano","khm"],
    ["Quirguiz","kir"],
    ["Curmânji (curdo - escrita latina)","kmr"],
    ["Coreano","kor"],
    ["Coreano (vertical)","kor_vert"],
    ["Curdo (escrita árabe)","kur"],
    ["Lao,Laosiano","lao"],
    ["Letão","lat"],
    ["Lituano","lav"],
    ["Luxemburguês","lit"],
    ["Luxembourgish","ltz"],
    ["Malaiala","mal"],
    ["Marata","mar"],
    ["Macedônio","mkd"],
    ["Maltês","mlt"],
    ["Mongol","mon"],
    ["Maori","mri"],
    ["Malaio","msa"],
    ["Birmanês","mya"],
    ["Nepalês","nep"],
    ["Holandês;Flamengo","nld"],
    ["Norueguês","nor"],
    ["Occitano (pós 1500)","oci"],
    ["Oriá","ori"],
    ["Módulo de orientação e detecção de script","osd"],
    ["Panjabi;Punjabi","pan"],
    ["Polonês","pol"],
    ["Português","por"],
    ["Pushto;Pashto,Pachto","pus"],
    ["Quíchua,Quechua","que"],
    ["Romeno;Moldávio;Moldávio","ron"],
    ["Russo","rus"],
    ["Sânscrito","san"],
    ["Cingalês;Cingalês","sin"],
    ["Eslovaco","slk"],
    ["Eslovaco - Fraktur (contrib)","slk_frak"],
    ["Esloveno","slv"],
    ["Sindi","snd"],
    ["Espanhol; Castelhano","spa"],
    ["Espanhol; Castelhano - Antigo","spa_old"],
    ["Albanês","sqi"],
    ["Sérvio","srp"],
    ["Sérvio - Latim","srp_latn"],
    ["Sudanês","sun"],
    ["Suaíli","swa"],
    ["Sueco","swe"],
    ["Siríaco","syr"],
    ["Tâmil","tam"],
    ["Tártaro","tat"],
    ["Télugo","tel"],
    ["Tadjique,Tajique","tgk"],
    ["Filipino,Tagalo,Tagalogue,Tagalog","tgl"],
    ["Tailandês","tha"],
    ["Tigrinya","tir"],
    ["Tonga","ton"],
    ["Turco","tur"],
    ["Uigur; Uigur","uig"],
    ["Ucraniano","ukr"],
    ["Urdu","urd"],
    ["Uzbeque","uzb"],
    ["Uzbeque - Cirílico","uzb_cyrl"],
    ["Vietnamita","vie"],
    ["Iídiche","yid"],
    ["Iorùbá","yor"],
    ["Árabe","arab"],
    ["Armênio","armn"],
    ["Bengali","beng"],
    ["Canadian_Aboriginal","cans"],
    ["Cherokee","cher"],
    ["Cirílico","cyrl"],
    ["Devanágari","deva"],
    ["Etíope","ethi"],
    ["Fraktur","frak"],
    ["Georgiano","geor"],
    ["Grego","grek"],
    ["Gujarate, Guzerate, Guzarate, Gujarati","gujr"],
    ["Gurmukhi, Panjábi","guru"],
    ["HanS (Han simplificado)","hans"],
    ["HanS_vert (Han vertical simplificado)","hans-vert"],
    ["HanT (Han tradicional)","hant"],
    ["HanT_vert (Han vertical tradicional)","hant-vert"],
    ["Hangul","hang"],
    ["Hangul_vert (Hangul vertical)","hang-vert"],
    ["Hebraico","hebr"],
    ["Japonês","jpan"],
    ["Japanese vertical","jpan-vert"],
    ["Kannada, Canarês","knda"],
    ["Khmer, Cambojano","khmr"],
    ["Lao, Laosiano","laoo"],
    ["Latim","latn"],
    ["Malayalam, Malaiala, Malaialim, Malabar","mlym"],
    ["Mianmar, Birmanês","mymr"],
    ["Oriá, Oriya (Odia)","orya"],
    ["Cingalês, Sinhala, Singhala","sinh"],
    ["Siríaco","syrc"],
    ["Tâmil","taml"],
    ["Télugo","telu"],
    ["Thaana, Taana","thaa"],
    ["Tailandês","thai"],
    ["Tibetano","tibt"],
    ["Vietnamita","viet"]
    ];

    const navigate = useNavigate();

    function irParaAjuda (){
        navigate('../ajuda/editor_de_texto');
    };

    idiomas.sort();

    const listaDeIdiomas = []
    let idioma = "";
    for (let i=0; i<=idiomas.length-1; i++)  {
    idioma = idiomas[i][0]
    listaDeIdiomas.push(idioma);
    };

    const changeLista = () => {
        linguagem = idiomas[document.getElementById("cmb-idiomas").selectedIndex][1];
        console.log(linguagem);
    }

    const handleSubmit = () => {
        linguagem = idiomas[document.getElementById("cmb-idiomas").selectedIndex][1];
        console.log(linguagem);
        setIsLoading(true);
        Tesseract.recognize(image, linguagem, {
            logger: (m) => {
            if (m.status === 'recognizing text') {
                setProgress(parseInt(m.progress * 100));
            }
            },
        })
        .catch((err) => {
        console.error(err);
        })
        .then((result) => {
        setText(result.data.text);
        document.getElementById("bloco-notas").value = document.getElementById("bloco-notas").value +"\n"+ result.data.text;
        setIsLoading(false);
        });
    };

    function diminuir () {
        var valor = 36;
        if(!localStorage.getItem('trpTamanhoFonte')){
            localStorage.setItem('trpTamanhoFonte', '36');
            valor = parseInt(localStorage.getItem('trpTamanhoFonte'));
            valor = valor - 3;
            localStorage.setItem('trpTamanhoFonte', (valor).toString());
            setTamanhoFonte(valor);
        }else{
            valor = parseInt(localStorage.getItem('trpTamanhoFonte'));
            valor = valor - 3;
            localStorage.setItem('trpTamanhoFonte', (valor).toString());
            setTamanhoFonte(valor);
        };
        localStorage.setItem('trpTamanhoFonte', valor);
    };

    function aumentar () {
        var valor = 36;
        if(!localStorage.getItem('trpTamanhoFonte')){
            localStorage.setItem('trpTamanhoFonte', '36');
            valor = parseInt(localStorage.getItem('trpTamanhoFonte'));
            valor = valor + 3;
            localStorage.setItem('trpTamanhoFonte', (valor).toString());
            setTamanhoFonte(valor);
        }else{
            valor = parseInt(localStorage.getItem('trpTamanhoFonte'));
            valor = valor + 3;
            localStorage.setItem('trpTamanhoFonte', (valor).toString());
            setTamanhoFonte(valor);
        };
        localStorage.setItem('trpTamanhoFonte', valor);
    };

    function selecionarTudo(){
        document.getElementById("bloco-notas").select();
    };

    function espacoEmBranco(){
        let texto = '';
        texto = document.getElementById("bloco-notas").value;
        texto = texto.replace(ExpReg.ESPACOS_EM_DUPLICIDADE[0],ExpReg.ESPACOS_EM_DUPLICIDADE[1]);
        texto = texto.replace(ExpReg.ESPACO_E_PONTUACAO[0],ExpReg.ESPACO_E_PONTUACAO[1]);
        document.getElementById("bloco-notas").value = texto; 
    };

    function nome(){
        let campo = document.getElementById("bloco-notas");
        let inicio = campo.selectionStart;
        let final = campo.selectionEnd;
        let selecionado = '';
        let texto = '';
        if (inicio === final) {
            ligaDesligaMensagem();
        }
        else {
            selecionado = campo.value;
            selecionado = selecionado.substring(inicio, final);
            selecionado = selecionado.toString().toLowerCase();
            selecionado = selecionado.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            selecionado = selecionado.trim(selecionado);
            let primeiraLetra = '';
            let palavras = selecionado.split(' ');
            if (palavras[0] !== ""){
                for (var i = 0; i < palavras.length; i++) {
                    primeiraLetra = palavras[i][0];
                    primeiraLetra = primeiraLetra.toString().toUpperCase();
                    palavras[i] = primeiraLetra + palavras[i].substring(1);
                }
                selecionado = palavras.join(' ');
                selecionado = selecionado.replace( ExpReg.NOME_DA[0]  , ExpReg.NOME_DA[1]  );
                selecionado = selecionado.replace( ExpReg.NOME_DAS[0] , ExpReg.NOME_DAS[1] );
                selecionado = selecionado.replace( ExpReg.NOME_DE[0]  , ExpReg.NOME_DE[1]  );
                selecionado = selecionado.replace( ExpReg.NOME_DEL[0] , ExpReg.NOME_DEL[1] );
                selecionado = selecionado.replace( ExpReg.NOME_DO[0]  , ExpReg.NOME_DO[1]  );
                selecionado = selecionado.replace( ExpReg.NOME_DOS[0] , ExpReg.NOME_DOS[1] );
                selecionado = selecionado.replace( ExpReg.NOME_E[0]   , ExpReg.NOME_E[1]   );
                selecionado = selecionado.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
                campo.value = campo.value.substring(0, inicio) + " " +
                selecionado + " " +
                campo.value.substring(final);

                texto = campo.value + '';
                texto = texto.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
                texto = texto.replace( ExpReg.ESPACO_E_PONTUACAO[0]     , ExpReg.ESPACO_E_PONTUACAO[1] );
                campo.value = texto;

            }else{
                ligaDesligaMensagem();
            }
        }
    };

    function maiuscula (){
        let campo = document.getElementById("bloco-notas");
        let inicio = campo.selectionStart;
        let final = campo.selectionEnd;
        let selecionado = '';
        let texto = '';
        if (inicio === final) {
            ligaDesligaMensagem();
        }
        else {
            inicio = campo.selectionStart;
            final = campo.selectionEnd;
            selecionado = campo.value.substring(inicio, final);
            selecionado = selecionado.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            selecionado = selecionado.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
            selecionado = selecionado.toString().toUpperCase();
            
            texto = campo.value.substring(0, inicio) +
            selecionado +
            campo.value.substring(final);
            texto = texto.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            texto = texto.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
            campo.value =texto;
        }
    };

    function minuscula (){
        let campo = document.getElementById("bloco-notas");
        let inicio = campo.selectionStart;
        let final = campo.selectionEnd;
        let selecionado = '';
        let texto = '';
        if (inicio === final) {
            ligaDesligaMensagem();
        }
        else {
            inicio = campo.selectionStart;
            final = campo.selectionEnd;
            selecionado = campo.value.substring(inicio, final);
            selecionado = selecionado.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            texto = selecionado.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            texto = selecionado.toString().toLowerCase();
            
            texto = campo.value.substring(0, inicio) +
            texto +
            campo.value.substring(final);
            texto = texto.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            texto = texto.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
            campo.value = texto;
        }
    };

    function data (){
        let campo = document.getElementById("bloco-notas");
        let inicio = campo.selectionStart;
        let final = campo.selectionEnd;
        let selecionado = '';
        let texto = '';
        if (inicio === final) {
            ligaDesligaMensagem();
        }
        else {
            selecionado = campo.value;
            selecionado = selecionado.substring(inicio, final);
            selecionado = selecionado.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            selecionado = selecionado.replace( ExpReg.JANEIRO[0] , ExpReg.JANEIRO[1] );
            selecionado = selecionado.replace( ExpReg.FEVEREIRO[0] , ExpReg.FEVEREIRO[1] );
            selecionado = selecionado.replace( ExpReg.MARCO[0] , ExpReg.MARCO[1] );
            selecionado = selecionado.replace( ExpReg.ABRIL[0] , ExpReg.ABRIL[1] );
            selecionado = selecionado.replace( ExpReg.MAIO[0] , ExpReg.MAIO[1] );
            selecionado = selecionado.replace( ExpReg.JUNHO[0] , ExpReg.JUNHO[1] );
            selecionado = selecionado.replace( ExpReg.JULHO[0] , ExpReg.JULHO[1] );
            selecionado = selecionado.replace( ExpReg.AGOSTO[0] , ExpReg.AGOSTO[1] );
            selecionado = selecionado.replace( ExpReg.SETEMBRO[0] , ExpReg.SETEMBRO[1] );
            selecionado = selecionado.replace( ExpReg.OUTUBRO[0] , ExpReg.OUTUBRO[1] );
            selecionado = selecionado.replace( ExpReg.NOVEMBRO[0] , ExpReg.NOVEMBRO[1] );
            selecionado = selecionado.replace( ExpReg.DEZEMBRO[0] , ExpReg.DEZEMBRO[1] );
            texto = campo.value.substring(0, inicio) +
            selecionado +
            campo.value.substring(final);
            texto = texto.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            texto = texto.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
            campo.value = texto;

        }
    };

    function quebrasDeLinha(){
        let campo = document.getElementById("bloco-notas");
        let inicio = campo.selectionStart;
        let final = campo.selectionEnd;
        let selecionado = '';
        let texto = '';
        if (inicio === final) {
            ligaDesligaMensagem();
        }
        else {
            selecionado = campo.value.substring(inicio, final);
            selecionado = selecionado.replace( ExpReg.QUEBRAS_DE_LINHAS[0] , ExpReg.QUEBRAS_DE_LINHAS[1] );
            texto = selecionado.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
            
            texto = campo.value.substring(0, inicio) +
            texto +
            campo.value.substring(final);
            texto = texto.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            texto = texto.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
            campo.value = texto;

        }
    };

    function juridico(){
        let campo = document.getElementById("bloco-notas");
        let inicio = campo.selectionStart;
        let final = campo.selectionEnd;
        let selecionado = '';
        let texto = '';
        if (inicio === final) {
            ligaDesligaMensagem();
        }
        else {
            selecionado = campo.value.substring(inicio, final);
            selecionado = selecionado.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            selecionado = selecionado.replace( ExpReg.NUMERO[0] , ExpReg.NUMERO[1] );
            selecionado = selecionado.replace( ExpReg.ARTIGO[0] , ExpReg.ARTIGO[1] );
            selecionado = selecionado.replace( ExpReg.CONSTITUICAO_FEDERAL[0] , ExpReg.CONSTITUICAO_FEDERAL[1] );
            selecionado = selecionado.replace( ExpReg.EMENDA_CONSTITUCIONAL[0] , ExpReg.EMENDA_CONSTITUCIONAL[1] );
            selecionado = selecionado.replace( ExpReg.LEI_COMPLEMENTAR_ESTADUAL[0] , ExpReg.LEI_COMPLEMENTAR_ESTADUAL[1] );
            selecionado = selecionado.replace( ExpReg.LEI_COMPLEMENTAR_FEDERAL[0] , ExpReg.LEI_COMPLEMENTAR_FEDERAL[1] );
            selecionado = selecionado.replace( ExpReg.RESOLUCAO[0] , ExpReg.RESOLUCAO[1] );
            selecionado = selecionado.replace( ExpReg.COMBINADO_COM[0] , ExpReg.COMBINADO_COM[1] );
            selecionado = selecionado.replace( ExpReg.COMPANHIA[0] , ExpReg.COMPANHIA[1] );
            selecionado = selecionado.replace( ExpReg.CODIGO_PENAL[0] , ExpReg.CODIGO_PENAL[1] );
            selecionado = selecionado.replace( ExpReg.CODIGO_PROCESSO_CIVIL[0] , ExpReg.CODIGO_PROCESSO_CIVIL[1] );
            selecionado = selecionado.replace( ExpReg.CODIGO_DE_PROCESSO_PENAL[0] , ExpReg.CODIGO_DE_PROCESSO_PENAL[1] );
            selecionado = selecionado.replace( ExpReg.DIARIO_OFICIAL_DO_ESTADO[0] , ExpReg.DIARIO_OFICIAL_DO_ESTADO[1] );
            selecionado = selecionado.replace( ExpReg.DIARIO_OFICIAL_DA_UNIAO[0] , ExpReg.DIARIO_OFICIAL_DA_UNIAO[1] );
            selecionado = selecionado.replace( ExpReg.DOUTOR[0] , ExpReg.DOUTOR[1] );
            selecionado = selecionado.replace( ExpReg.MINISTERIO_PUBLICO[0] , ExpReg.MINISTERIO_PUBLICO[1] );
            selecionado = selecionado.replace( ExpReg.SOCIEDADE_ANONIMA[0] , ExpReg.SOCIEDADE_ANONIMA[1] );
            selecionado = selecionado.replace( ExpReg.JANEIRO[0] , ExpReg.JANEIRO[1] );
            selecionado = selecionado.replace( ExpReg.FEVEREIRO[0] , ExpReg.FEVEREIRO[1] );
            selecionado = selecionado.replace( ExpReg.MARCO[0] , ExpReg.MARCO[1] );
            selecionado = selecionado.replace( ExpReg.ABRIL[0] , ExpReg.ABRIL[1] );
            selecionado = selecionado.replace( ExpReg.MAIO[0] , ExpReg.MAIO[1] );
            selecionado = selecionado.replace( ExpReg.JUNHO[0] , ExpReg.JUNHO[1] );
            selecionado = selecionado.replace( ExpReg.JULHO[0] , ExpReg.JULHO[1] );
            selecionado = selecionado.replace( ExpReg.AGOSTO[0] , ExpReg.AGOSTO[1] );
            selecionado = selecionado.replace( ExpReg.SETEMBRO[0] , ExpReg.SETEMBRO[1] );
            selecionado = selecionado.replace( ExpReg.OUTUBRO[0] , ExpReg.OUTUBRO[1] );
            selecionado = selecionado.replace( ExpReg.NOVEMBRO[0] , ExpReg.NOVEMBRO[1] );
            selecionado = selecionado.replace( ExpReg.DEZEMBRO[0] , ExpReg.DEZEMBRO[1] );
            selecionado = selecionado.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            selecionado = selecionado.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
            
            texto = campo.value.substring(0, inicio) +
            selecionado +
            campo.value.substring(final);
            texto = texto.replace( ExpReg.ESPACOS_EM_DUPLICIDADE[0] , ExpReg.ESPACOS_EM_DUPLICIDADE[1] );
            texto = texto.replace( ExpReg.ESPACO_E_PONTUACAO[0] , ExpReg.ESPACO_E_PONTUACAO[1] );
            campo.value = texto;

        }
    };

    function ligaDesligaMensagem(){
        document.getElementById("mensagem").classList.remove("apagado");
        document.getElementById("mensagem").classList.add("aceso");
        setTimeout(function(){
            document.getElementById("mensagem").style.display = 'block';
            setTimeout(function(){
                document.getElementById("mensagem").classList.remove("aceso");
                document.getElementById("mensagem").classList.add("apagado");
                setTimeout(function(){
                        document.getElementById("mensagem").style.display = 'none';
                },500);
            },3000);
        },500);
        
    };

    function copiar(){
        document.getElementById("bloco-notas").select();
        document.execCommand('copy');
    };

    function colar(){
        navigator.clipboard.readText()
        .then(text => {
            document.getElementById("bloco-notas").value = document.getElementById("bloco-notas").value + '\n\n'+ text;
            console.log(text);
        })
        .catch(err => {
            console.error('Erro ao colar texto: ', err);
        });
    }

    function recortar() {
        var campo = document.getElementById('bloco-notas');
        var textoSelecionado = campo.value.substring(campo.selectionStart, campo.selectionEnd);
        let inicio = campo.selectionStart;
        let final = campo.selectionEnd;


        if (inicio === final) {
            ligaDesligaMensagem();
        }
        else {
            if (textoSelecionado) {
                navigator.clipboard.writeText(textoSelecionado)
                    .then(() => {
                        campo.value = campo.value.substring(0, campo.selectionStart) + campo.value.substring(campo.selectionEnd);
                    })
                    .catch(err => {
                        console.error('Erro ao recortar texto: ', err);
                    });
            }
        }
    }

    return(
        <EstiloPaginaEditorTexto id='container-pagina-editor-texto'>
            <div className='formulario'>
                <div>
                    <BsFillQuestionCircleFill  className='botao-ajuda' title="Ajuda sobre os botões." id="btn-ajuda" onClick={irParaAjuda} />
                </div>
                
                <Grupo nome="Extrair texto de imagem">
                    <GrupoBotoes nome="O texto está em :">
                    <div className='entrada-texto'>
                        <select className='combobox' name={"idiomas"} id={"cmb-idiomas"} defaultValue={"Português"} onChange={changeLista}>
                            {listaDeIdiomas.map((idioma) => (
                                <option key={`opt-${++i}`} value={idioma} id={`opt-${tratamentoNomeCampo("idioma")}-${idioma}`} >{idioma}</option>
                            ))}
                        </select>
                    </div>
                    </GrupoBotoes>
                    <GrupoBotoes nome="Imagem">
                        {isLoading && (
                        <div>
                        <progress className="form-control" value={progress} max="100">
                            {progress}%{' '}
                        </progress>{' '}
                        <p className="text-center py-0 my-0">Converting:- {progress} %</p>
                        </div>
                        )}
                        {!isLoading && !text && (
                        <div>
                            <input
                            type="file"
                            className="entrada-texto"
                            onChange={(e) =>
                            setImage(URL.createObjectURL(e.target.files[0]))
                            }/>
                            <MdVisibility  onClick={handleSubmit} className="botao" title="Converter"/>
                        </div>
                        )}
                        {!isLoading && text && (
                        <div>
                            <input
                            type="file"
                            className="entrada-texto"
                            onChange={(e) =>
                            setImage(URL.createObjectURL(e.target.files[0]))
                            }/>
                            <MdVisibility  onClick={handleSubmit} className="botao" title="Converter"/>
                            
                        </div>
                        )}
                    </GrupoBotoes>
                </Grupo>
                <Grupo nome="Tratamento de Texto">
                    <GrupoBotoes nome="Zoom">
                        <FaMinusCircle className='botao' title='Diminuir a Fonte' id='btn-diminui' onClick={diminuir}/>
                        <FaPlusCircle  className='botao' title='Aumentar a Fonte' id='btn-aumenta' onClick={aumentar}/>
                    </GrupoBotoes>
                    <GrupoBotoes nome="Geral">
                        <FaCheckCircle className='botao' title='Selecionar todo o texto' id='btn-selecionar-tudo' onClick={selecionarTudo}/>
                        <FaRocket className='botao' title='Espaços em branco' id='btn-espaco-branco' onClick={espacoEmBranco}/>
                    </GrupoBotoes>
                    <GrupoBotoes nome="Fonte">
                        <FaUserCircle className='botao' title='Tratamento de nome' id='btn-nome' onClick={nome}/>
                        <TbLetterCaseUpper className='botao' title='Letras Maiúsculas' id='btn-maiuscula' onClick={maiuscula}/>
                        <TbLetterCaseLower className='botao' title='Letras Minusculas' id='btn-minuscula' onClick={minuscula}/>
                    </GrupoBotoes>
                    <GrupoBotoes nome="Básico">
                        <FaCut className='botao' title='recortar o texto selecionado' id='btn-recortar' onClick={recortar}/>
                        <FaRegCopy className='botao' title='copiar todo o texto' id='btn-copiar' onClick={copiar}/>
                        <FaRegPaste className='botao' title='colar o texto da área de transferência' id='btn-colar' onClick={colar}/>
                    </GrupoBotoes>
                    <GrupoBotoes nome="Outros">
                        <BsFillCalendarDateFill className='botao' title='Tratamento de datas' id='btn-data' onClick={data}/>
                        <IoEnter className='botao' title='Tratamento de quebras de linhas' id='btn-quebra-linha' onClick={quebrasDeLinha}/>
                        <ImHammer2 className='botao' title='Tratamento termos jurídicos' id='btn-juridico' onClick={juridico}/>
                    </GrupoBotoes>
                </Grupo>
                <br></br>
                <div id="mensagem"><BsInfoCircleFill className='botao'/> <p>Selecione o texto.</p></div>
                <br></br>
                <textarea id="bloco-notas" style={{fontSize:`${tamanhoFonte}px`}}></textarea>
                <Tags lista={["editor de texto", "retirar multiplos espaços em branco", "retirar quebras de linha", "tratamento de termos jurídicos", "tratamento de nome", "ocr", "extrair texto de imagem", "uso da biblioteca tesseract.js"]}/>
            </div>
        </EstiloPaginaEditorTexto>
    );
};

