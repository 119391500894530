import React from "react";
import styled from 'styled-components';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';



const EstiloConteudoRelatorio = styled.div`
    background-color: ${({theme}) => theme.tmCorpo};
    border: 1px solid black;

    float: left;
    padding: 20px 20px 30px 20px;
    margin: 0px 30px 30px 30px;
    display: table; 

    @media (min-width: 320px) and (max-width: 480px) {
        width: 70%;

        img{
            max-width: 70%;
            max-height: 25%;
        } 

    }

    @media (min-width: 481px){
        width: 90%;

        img{
            max-width: 25%;
            max-height: 25%;
        } 
    }

    a {
        text-decoration: none;
    }

   li {
        list-style: decimal;
        margin: 0px 0px 0px 60px;
    } 

    h1{
        font-size: 30px;
        font-weight: 800;
        line-height: 2rem;
        margin: 20px 0px 20px 20px;
        text-align: left;
    }

    h2{
        font-size: 20px;
        font-weight: 800;
        line-height: 2rem;
        line-height: 2rem;
        margin: 10px 0px 10px 20px;
        text-align: left;
    }

    h3{
        font-size: 30px;
        font-weight: 800;
        line-height: 2rem;
        margin: 20px 0px 20px 20px;
        text-align: center
    }

    h4{
        font-size: 20px;
        font-weight: 800;
        line-height: 2rem;
        margin: 10px 0px 10px 20px;
        text-align: center;
    }

    h5{
        font-size: 16px;
        font-weight: 400;
        line-height: 2rem;
        margin: 0px 0px 0px 40px;
        text-align: center;
    }

    h6{
        font-size: 16px;
        font-weight: 400; 
        margin-left: 20px;
        line-height: 1.5rem;
        margin: 0px 0px 0px 40px;
        margin: 0% 1% 0% 55%;
    }

    p {
        font-size: 16px;
        font-weight: 400; 
        margin-left: 20px;
        line-height: 1.5rem;
        margin: 0px 0px 0px 40px;
        width:100%;
    }

    ul{
        li{
            p{
                margin: 0px 50px 0px 40px;
                input{
                    margin: 0px 10px 0px -25px;
                }
            }
        }
    }

    blockquote{
        margin: 20px 20px 20px 20px;
    } 

    .table-container{
        height: 400px;
        width: 400px;
        margin: 0 auto;

        table{
            width: auto;
            border-collapse: collapse;

            th, td{
            border: 1px solid #ccc;
            padding: 5px;
            }

            th{
                font-weight: 400;
            }

        }

    }

`;

interface ConteudoRelatorioProps {
    texto:string;
}

const ConteudoRelatorio = ({texto}:ConteudoRelatorioProps) => {
    return(
        <>
            <EstiloConteudoRelatorio id='container-conteudo-relatorio'>
                <Markdown children={texto} remarkPlugins={[remarkGfm]}/>
            </EstiloConteudoRelatorio>
        </>
    );
};

export default ConteudoRelatorio;