import styled from 'styled-components';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import relatorios from '../../json/relatorios.json';
import { tratamentoNomeCampo } from '../../js/convesoes';
import { useState } from 'react';
import SubTitulo from '../../componentes/SubTitulo/index';
import ConteudoRelatorio from '../../componentes/ConteudoRelatorio/index';
import Tags from '../../componentes/Tags/index';
import { ImPrinter } from "react-icons/im";
import { IoMdSave } from "react-icons/io";





const EstiloPaginaRelatorio = styled.div`
    background-color: ${({ theme }) => theme.corpo};

/* ----------- area do botao de zoom---------*/

    .conteudoGrupo {
    height: 10px;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    }

    #grp-botoes {
        width: 10%;
        height: 100px;
        display: table;
        margin: 20px 20px 40px 50px;
        padding: 0px 0px 0px 0px;
    }

    #grp-botoes .tituloGrupo {
        width: 100%;
        height: 30px;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        display: table;

    }

    #grpb-zoom {
        width: 90%;
        height: 60px;
        margin: 10px 20px 10px 10px;
        padding: 0px 0px 0px 0px;
        display: table;
    }

    .legGrupoBotoes {
        margin: 0px 0px 4px 0px;
    }

    .botao{
        color:${({theme}) => theme.tmBotao};
        width: 80px;
        height: 30px;
        margin: 0px 0px;
        padding: 0px 0px 0px 0px;

        &:hover{
            color:${({theme}) => theme.tmBotaoHover};
        }
    } 

    /* ----------- area do bloco de notas--------*/

    #bloco-notas{
        float: left;
        width: 95%;
        height: 400px;
        margin: 0px 5px 0px 50px;
        padding: 0px 0px 0px 0px;
        font-size: ${localStorage.getItem('trpTamanhoFonte')}px;
        resize: vertical;
    }


    .etiquetacodigo {
        float: left;
        background-color: transparent; 
        margin: 20px 0px 20px 50px;
        padding: 0px 0px;
        font-weight: bold;
    }



    /* ----------- area do formulario---------*/

    .area-do-formulario {
        float: left;
        background-color: ${({ theme }) => theme.corpo};
        height: 150px;
        width: 99%;
        margin: 20px 5px 0px 50px;
        display: table;
    }

    .etiquetaformulario {
        float: left;
        background-color: ${({ theme }) => theme.corpo};
        margin: 0px 0px 0px 0px;
        padding: 0px 0px;
        font-weight: bold;
    }

    .area-botao-script{
        background-color: ${({ theme }) => theme.corpo};
        float: left;
        height: 0px;
        width: auto;
        margin: 25px 5px 0px 50px;
        padding: 0px 0px;
        display: table;
    } 

    #formulario-relatorio-dinamico{
        background-color: ${({ theme }) => theme.tmAjuda};
        float: left;
        height: 200px;
        width: 95%;
        margin: 10px 5px 0px 50px;
        padding: 0px 0px 0px 0px;
        display: table;
    }



    #tb-formulario {
        background-color: ${({ theme }) => theme.corpo};
        line-height: 40px;
        width: 60%;
        text-align: left;
    }

    #tb-formulario tr{
        width: 100%;
        background-color: ${({ theme }) => theme.corpo};
        display: table; 
    }

    #tb-formulario th{
        width: 60%;
        background-color: ${({ theme }) => theme.corpo};
        display: table; 
    }


    #tb-formulario th input {
        background-color: ${({ theme }) => theme.corpo};
        width: 100%;

    }


    /* ----------- area do relatorio---------*/

    .relatorio {
        float: left;
        background-color: white;
        height: 200px;
        width: 95%;
        margin: 20px 5px 50px 50px;
        padding: 0px 0px;
        display: table;
    }

    .etiquetarelatorio {
        float: left;
        background-color: ${({ theme }) => theme.corpo};
        margin: 0px 0px 0px 0px;
        padding: 20px 0px 0px 50px;
        font-weight: bold;
    }


    .area-botao-relatorio {
        float: left;
        background-color: ${({ theme }) => theme.corpo};
        height: 0px;
        width: 100%;
        margin: 25px 5px 0px 50px;
        padding: 0px 0px;
        display: table;
    } 

    #relatorio{
        float: left;
        /* background-color: #fff; */
        height: auto;
        width: 100%;
        margin: 20px 5px 50px 0px;
        padding: 0px 0px 30px 0px;
    }

    #formulario-relatorio-dinamico {
        background-color: ${({ theme }) => theme.corpo};
        margin: 20px 0px 20px 0px;
        padding: 20px 0px 0px 20px;
    }

    #btn-imprimir{
        width: 30px;
        height: 30px;
        float:left;
        margin: 0px 10px 0px 50px;
        color: ${({ theme }) => theme.tmTituloFundo};
        /* background-color: #4E6E81; */

        &:hover {
            border: none;
            color: ${({ theme }) => theme.tmAjuda};
            transition: .3s;
            cursor:pointer;
        }
    } 

    #btn-salvar{
        width: 30px;
        height: 30px;
        float:left;
        margin: 0px 70px 0px 0px;
        color: ${({ theme }) => theme.tmTituloFundo};

        &:hover {
            border: none;
            color: ${({ theme }) => theme.tmAjuda};
            transition: .3s;
            cursor:pointer;
        }
    } 

`;

export default function PaginaRelatorio() {
    const [getRelatorio,setRelatorio] = useState("");
    const [getFormulario,setFormulario] = useState("");
    const [getPrimeiraVez,setPrimeiraVez] = useState(true);
    const parametros = useParams();

    const relatorio = relatorios.find((relatorio) => {
        return relatorio.id === Number(parametros.id);
    });

    if(getPrimeiraVez){
        const itens = new Map();
        const formulario = {
            id:"x",
            codigo:"x",
            campos:"x",
            relatorio:"x"
        }
    
        const relatorio = relatorios.find((relatorio) => {
            return relatorio.id === Number(parametros.id);
        });    
    
        /* atualiza_formulario */
        formulario.id = relatorio.id;
        formulario.codigo = relatorio.codigo;
        var lista = relatorio.codigo.matchAll(/(!!)(.{1}|.{2}|.{3}|.{4}|.{5}|.{6}|.{7}|.{8}|.{9}|.{10}|.{11}|.{12}|.{13}|.{14}|.{15}|.{16}|.{17}|.{18}|.{19}|.{20}|.{21}|.{22}|.{23}|.{24}|.{25}|.{26}|.{27}|.{28}|.{29}|.{30}|.{31}|.{32}|.{33}|.{34}|.{35}|.{36}|.{37}|.{38}|.{39}|.{40}|.{41}|.{42}|.{43}|.{44}|.{45}|.{46}|.{47}|.{48}|.{49}|.{50}|.{60}|.{61}|.{62}|.{63}|.{64}|.{65}|.{66}|.{67}|.{68}|.{69}|.{70}|.{71}|.{72}|.{73}|.{74}|.{75}|.{76}|.{77}|.{78}|.{79}|.{80}|.{81}|.{82}|.{83}|.{84}|.{85}|.{86}|.{87}|.{88}|.{89}|.{90}|.{91}|.{92}|.{93}|.{94}|.{95}|.{96}|.{97}|.{98}|.{99})(!!)/g);
        var inicio = 0;
        var final = 0;
        var campo = '';
        for (const item of lista){
            inicio = item.index +2 ;
            final = item.index + item[0].length -2;
            itens.set(formulario.codigo.substring(inicio, final));
        }
    
        formulario.campos=[];
        for(const item of itens){
            campo = {
                id:`ipt-txt-${tratamentoNomeCampo(item[0])}`,
                desc: item[0]
            }
            formulario.campos.push(campo);
        }

        setFormulario(formulario);
    
        setTimeout(function(){
            /* Apagando os campos existentes em tb-formulario */
            const pai = document.getElementById("tb-formulario");
                while (pai.hasChildNodes()) {
                    pai.removeChild(pai.firstChild);
            }
            var novo_obj = '';
            var linha = '';
            var coluna_esq = '';
            var coluna_dir = '';
            for(campo in formulario.campos){
                linha = document.createElement("tr");
                coluna_esq = document.createElement("th");
                novo_obj = document.createElement("label");
                novo_obj.className = `lbl-formulario`;
                novo_obj.innerHTML = formulario.campos[campo].desc + ': ';
                coluna_esq.appendChild(novo_obj);
                coluna_dir = document.createElement("th");
                novo_obj = document.createElement("input");
                novo_obj.className = `ipt-formulario`;
                novo_obj.id = formulario.campos[campo].id;
                coluna_dir.appendChild(novo_obj);
                linha.appendChild(coluna_esq);
                linha.appendChild(coluna_dir);
                pai.appendChild(linha);
            }
        },500);
        setPrimeiraVez(false);
    }
    
    function atualiza_relatorio(){
        var busca = '';
        var substituir = '';
        var texto = getFormulario.codigo;
        for( const campo in getFormulario.campos){
            busca = getFormulario.campos[campo].desc;
            substituir = document.getElementById(getFormulario.campos[campo].id).value
            texto = texto.replaceAll(`!!${busca}!!`,substituir);
        }
        texto.toString(texto);
        setRelatorio(texto);
    }


    function imprimir() {
        var pai = document.querySelector("#relatorio").parentNode;
        var relatorio = pai.querySelector("#container-conteudo-relatorio").innerHTML;
        // CRIA UM OBJETO WINDOW
        var estilo = "<style> img, a { max-width: 70%; max-height: 25%; } li { list-style: decimal; margin: 0px 0px 0px 60px; } h1{ font-size: 30px; font-weight: 800; line-height: 2rem; margin: 20px 0px 20px 20px; text-align: left; } h2{ font-size: 20px; font-weight: 800; line-height: 2rem; line-height: 2rem; margin: 10px 0px 10px 20px; text-align: left; } h3{ font-size: 30px; font-weight: 800; line-height: 2rem; margin: 20px 0px 20px 20px; text-align: center } h4{ font-size: 20px; font-weight: 800; line-height: 2rem; margin: 10px 0px 10px 20px; text-align: center; } h5{ font-size: 16px; font-weight: 400; line-height: 2rem; margin: 0px 0px 0px 40px; text-align: center; } h6{ font-size: 16px; font-weight: 400; margin-left: 20px; line-height: 1.5rem; margin: 0px 0px 0px 40px; margin: 0% 1% 0% 55%; } p { font-size: 16px; font-weight: 400; margin-left: 20px; line-height: 1.5rem; margin: 0px 0px 0px 40px; width:100%; } ul{ li{ p{ margin: 0px 50px 0px 40px; input{ margin: 0px 10px 0px -25px; } } } } blockquote{ margin: 20px 20px 20px 20px; }. table-container{ height: 400px; width: 400px; margin: 0 auto; table{ width: auto; border-collapse: collapse; th, td{ border: 1px solid #ccc; padding: 5px; } th{ font-weight: 400; } } }</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>'); 
        win.document.write(estilo);                                    
        win.document.write('</head>');                                   
        win.document.write('<body>');
        win.document.write(relatorio);                         
        win.document.write('</body></html>');                         
        win.print(); 
        win.close();                                                      
    }

    function salvar(){
        var filename = 'relatorio';
        var pai = document.querySelector("#relatorio").parentNode;
        var relatorio = pai.querySelector("#container-conteudo-relatorio").innerHTML;
        var estilo = "<style> img, a { max-width: 70%; max-height: 25%; } li { list-style: decimal; margin: 0px 0px 0px 60px; } h1{ font-size: 30px; font-weight: 800; line-height: 2rem; margin: 20px 0px 20px 20px; text-align: left; } h2{ font-size: 20px; font-weight: 800; line-height: 2rem; line-height: 2rem; margin: 10px 0px 10px 20px; text-align: left; } h3{ font-size: 30px; font-weight: 800; line-height: 2rem; margin: 20px 0px 20px 20px; text-align: center } h4{ font-size: 20px; font-weight: 800; line-height: 2rem; margin: 10px 0px 10px 20px; text-align: center; } h5{ font-size: 16px; font-weight: 400; line-height: 2rem; margin: 0px 0px 0px 40px; text-align: center; } h6{ font-size: 16px; font-weight: 400; margin-left: 20px; line-height: 1.5rem; margin: 0px 0px 0px 850px; } p { font-size: 16px; font-weight: 400; margin-left: 20px; line-height: 1.5rem; margin: 0px 0px 0px 40px; width:100%; } ul{ li{ p{ margin: 0px 50px 0px 40px; input{ margin: 0px 10px 0px -25px; } } } } blockquote{ margin: 20px 20px 20px 20px; }. table-container{ height: 400px; width: 400px; margin: 0 auto; table{ width: auto; border-collapse: collapse; th, td{ border: 1px solid #ccc; padding: 5px; } th{ font-weight: 400; } } }</style>";
        var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title> ";
        preHtml = preHtml+estilo+" </head><body>";
        var postHtml = "</body></html>";
        var html = preHtml+relatorio+postHtml;
        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });
        
        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        // Specify file name
        filename = filename?filename+'.doc':'document.doc';
        // Create download link element
        var downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        
        if(navigator.msSaveOrOpenBlob ){
            navigator.msSaveOrOpenBlob(blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = url;
            // Setting the file name
            downloadLink.download = filename;
            //triggering the function
            downloadLink.click();
        }
        document.body.removeChild(downloadLink);
    }

    return(
        <>
            <SubTitulo texto={relatorio.titulo}/>
            <EstiloPaginaRelatorio id='container-pagina-relatorio'>
                <div className='area-do-formulario'>
                    <label className="etiquetaformulario">Formulário:</label>
                    <form id='formulario-relatorio-dinamico'>
                        <table id='tb-formulario'>
                        </table>
                    </form>
                </div>
                <div className='area-botao-relatorio'>
                        {/* Montar Relatório concatenando texto e valores dos campos do formulário */}
                    <button id='btn-cria-relatorio' onClick={atualiza_relatorio}>Atualiza Relatório</button>
                </div>
                <label className="etiquetarelatorio">Relatório:</label>
                <div id='relatorio'>
                    <ConteudoRelatorio texto={getRelatorio}/>
                    <ImPrinter className='botao' id="btn-imprimir" title="Imprimir" onClick={imprimir}></ImPrinter>
                    <IoMdSave className='botao' id="btn-salvar" title="Salvar" onClick={salvar}></IoMdSave>
                </div>
            </EstiloPaginaRelatorio>
            <Tags lista={relatorio.tags}/>
        </>
    );
};



