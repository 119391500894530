import styled from 'styled-components';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import relatorios from '../../json/relatorios.json';
/* import { useState } from 'react'; */
import ConteudoPagina from '../../componentes/ConteudoPagina/index';

const EstiloConteudoPagina = styled.section`
    min-height: 500px;
    background-color: ${({theme}) => theme.tmCorpo};
    margin: 0px auto 0px auto;
    padding-bottom: 40px;

    .formulario{
        background-color:${({theme}) => theme.tmCorpo};
        width: 5%;
        display: table;
        margin: 0px auto 0px auto;
        padding: 15px 0px 15px 0px;

    }
`;

export default function PaginaBlog() {
    const parametros = useParams();
    const relatorio = relatorios.find((relatorio) => {
        return relatorio.id === Number(parametros.id);
    });
    return(
        <>
            <EstiloConteudoPagina>
                <ConteudoPagina titulo={relatorio.titulo} texto={relatorio.codigo} tags={relatorio.tags}/>
            </EstiloConteudoPagina>
        </>
    );
};


