import React from "react";
import styled from 'styled-components';
/* import {ImCheckboxChecked,ImCheckboxUnchecked} from 'react-icons/im'; */
import { atualizaVariaveisLocais } from '../../js/versao';
import Tags from '../../componentes/Tags/index';
import { FaCity } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";

const EstiloPaginaHome = styled.section`
    float: left;
    padding: 20px 20px 30px 20px;
    margin: 0px 30px 30px 30px;
    background-color: ${({theme}) => theme.tmCorpo};
    display: table; 

    p{
        font-size: 16px;
    }

    .predios{
        width: 100px;
        height: 100px;
        margin: 20px 20px 20px 20px;
        color:${({theme}) => theme.tmBotao};
    }

    @media (min-width: 320px) and (max-width: 480px) {
        img{
            max-width: 70%;
            max-height: 25%;
        } 

        .table-container{
            width: 70%;
            margin: 0 auto;

            table{
                width: auto;
                border-collapse: collapse;
        
                th, td{
                border: 1px solid #ccc;
                padding: 5px;
                }
        
                th{
                    font-weight: 400;
                }
            }
        }
    }

    @media (min-width: 481px){
        img{
            max-width: 25%;
            max-height: 25%;
        } 

        .table-container{
            width: 90%;
            margin: 0 auto;

            table{
                width: auto;
                border-collapse: collapse;
        
                th, td{
                border: 1px solid #ccc;
                padding: 5px;
                }
        
                th{
                    font-weight: 400;
                }
            }
        }
    }
`;

export default function PaginaHome () {
    
    var versao_local = localStorage.getItem('trpVersao');
    atualizaVariaveisLocais(versao_local);

    return(
        <EstiloPaginaHome id='container-pagina-home'>
            <FaCity className='predios'/>
            <FaTreeCity className='predios'/>
            <br/>
            <p>
                Seja bem-vindo.
            </p>
            <br/>
            <br/>
            <p> Este site tem como objetivo criar um ambiente para estudantes e trabalhadores.
            </p>
            <br/>
            <p>
                O projeto iniciou com a necessidade de reduzir ações repetitivas, trabalhar em grupo e me reorganizar, com essa motivação,
                busquei novas alternativas para ganhar tempo, diminuir erros e ser mais produtivo.
            </p>
            <br/>
            <p>
                Saiba mais sobre a proposta deste site em nosso blog.
            </p>
            <br/><br/><br/>
            <p>
                <b>Antonino Marques Jares</b>
            </p>
            <br/>
            <Tags lista={["apresentação", "convite a conhecer o site", "estudantes", "trabalhadores", "editor de texto", "relatório dinâmico", "grupo de trabalho", "alterar as cores do site", "alterar o grupo de trabalho", "blog"]}/>
        </EstiloPaginaHome>
    );
};

