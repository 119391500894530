import React, { useState } from 'react';
import styled from 'styled-components';
import { IoAddCircle } from "react-icons/io5";

const EstiloPaginaTeste = styled.div`

  font-family: 'Nunito';
  background-color: #d4865f;
  color: white;


  #boards {
      display: flex;
      margin-top: 32px;
  }

  .board {
      background: #141316;
      border: 1px solid #FD951F11;
      border-radius: 4px;
      margin: 0 16px;
  }

  .board h3 {
      padding: 16px;
      margin: 0;
      color: white;
  }

  .dropzone {
    background-color: #878787;
      padding: 16px;
      min-width: 282px;
      min-height: 200px;
  }

  .card {
      background-color: #7d7de7;
      padding: 16px;
      box-shadow: 0 2px 2px -1px #FD951Fcc;
      width: 250px;
      margin: 25px 0;
      border-radius: 4px;
      font-weight: 600;
      font-size: 18px;
      
  }

  .status {
      width: 30px;
      height: 8px;
      background: gray;
      margin-bottom: 16px;
      border-radius: 8px;
  }

  .status.green {
      background: #23d2ac;
  }

  .status.blue {
      background: #33adff;
  }

  .status.red {
      background: red;
  }

  .highlight {
      background-color: #FD951F08;
  }

  .card, .dropzone {
      transition: 400ms;
  }

  .is-dragging {
      cursor: move;
      opacity: 0.3;
  }

  .over {
      background-color: #4cd13711;
  }

`;

export default function PaginaTeste() {
  const style_board = `background: #141316;border: 1px solid #FD951F11;border-radius: 4px;margin: 0 16px;`;
  const style_titulo = `padding: 16px;margin: 0;color: white;`;
  const style_dropzone = `background-color: #878787;padding: 16px;min-width: 282px;min-height: 200px;transition: 400ms;`;
  const style_card = `background-color: #7d7de7; padding: 16px;box-shadow: 0 2px 2px -1px #FD951Fcc; width: 250px; margin: 25px 0; border-radius: 4px; font-weight: 600; font-size: 18px;transition: 400ms;`;
  const style_status = `width: 30px; height: 8px; background: gray; margin-bottom: 16px; border-radius: 8px; background: #23d2ac;`;
  
  const [cards, setCards] = useState(document.querySelectorAll('.card'));
  const [dropzones, setDropzones] = useState(document.querySelectorAll('.dropzone'));

  function dragstart() {
    // log('CARD: Start dragging ')
    dropzones.forEach( dropzone => dropzone.classList.add('highlight'))

    // this = card
    this.classList.add('is-dragging')
  }

  function drag() {
    // log('CARD: Is dragging ')
  }

  function dragend() {
    // log('CARD: Stop drag! ')
    dropzones.forEach( dropzone => dropzone.classList.remove('highlight'))

    // this = card
    this.classList.remove('is-dragging')
  }

  /** our cards */
  cards.forEach(card => {
    card.addEventListener('dragstart', dragstart)
    card.addEventListener('drag', drag)
    card.addEventListener('dragend', dragend)
  })

  /** place where we will drop cards */
  dropzones.forEach( dropzone => {
    dropzone.addEventListener('dragenter', dragenter);
    dropzone.addEventListener('dragover', dragover);
    dropzone.addEventListener('dragleave', dragleave);
    dropzone.addEventListener('drop', drop);
  })

  function dragenter() {
    // log('DROPZONE: Enter in zone ')
  }

  function dragover() {
    // this = dropzone
    this.classList.add('over')

    // get dragging card
    const cardBeingDragged = document.querySelector('.is-dragging')

    // this = dropzone
    this.appendChild(cardBeingDragged)
  }

  function dragleave() {
    // log('DROPZONE: Leave ')
    // this = dropzone
    this.classList.remove('over')

  }

  function drop() {
    // log('DROPZONE: dropped ')
    this.classList.remove('over')
  }

  function novoBoard(){
    var bords = document.getElementById('boards');
    var bord = document.createElement("div");
    bord.className="bord";
    bord.style = style_board;
    var titulo = document.createElement("h3");
    titulo.innerHTML='Board';
    titulo.style = style_titulo;
    var dropZone = document.createElement("div");
    dropZone.className="dropzone";
    dropZone.style = style_dropzone;
    dropZone.addEventListener('dragenter', dragenter);
    dropZone.addEventListener('dragover', dragover);
    dropZone.addEventListener('dragleave', dragleave);
    dropZone.addEventListener('drop', drop);
    bord.appendChild(titulo);
    bord.appendChild(dropZone);
    bords.appendChild(bord);

    /** Atualiza todos os grupos */
    setCards(document.querySelectorAll('.card'));
    setDropzones(document.querySelectorAll('.dropzone'));
  }

  function novoCard(){
    var dropz = document.getElementById('dropzone-principal');
    var card = document.createElement("div");
    card.className="card";
    card.style = style_card;
    card.draggable='true';
    card.addEventListener('dragstart', dragstart);
    card.addEventListener('drag', drag);
    card.addEventListener('dragend', dragend);
    var status = document.createElement("div");
    status.className="status green";
    status.style = style_status;
    status.draggable = 'true';
    var content = document.createElement("div");
    content.innerHTML='Texto do Card';
    card.appendChild(status);
    card.appendChild(content);

    dropz.appendChild(card);

     /** Atualiza todos os grupos */
     setCards(document.querySelectorAll('.card'));
     setDropzones(document.querySelectorAll('.dropzone'));
    
  }

 return(
        <EstiloPaginaTeste>
          <>
            <div>
              <IoAddCircle onClick={novoBoard}/>
              <IoAddCircle onClick={novoCard}/>
            </div>

            <div id="boards">
                <div className="board">
                    <h3>Todo</h3>
                    <div className="dropzone" id="dropzone-principal">
                        <div className="card" draggable="true">
                            <div className="status green"></div>
                            <div className="content"> Do videos!</div>
                        </div>
                    </div>
                </div>
                <div className="board">
                    <h3>In progress</h3>
                    <div className="dropzone">
                        <div className="card" draggable="true">
                            <div className="status blue"></div>
                            <div className="content"> Forum</div>
                        </div>
                    </div>
                </div>
                <div className="board">
                    <h3>Done</h3>
                    <div className="dropzone">
                        <div className="card" draggable="true">
                            <div className="status red"></div>
                            <div className="content"> Next Level Week</div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        </EstiloPaginaTeste>
    );
};

