
function atualizaVariaveisLocais(versao){
    /* Garanto que terá todas as variáveis Locais */
    setTimeout(function(){
        var versao_atual = '1.1';
        if(!versao){
            localStorage.setItem('trpVersao',versao_atual);
            localStorage.setItem('trpTema','laranja');
            localStorage.setItem('trpGrupo','Internauta');
        }else{
            if(Number(versao) === 1.0){
                /* Se versão 1.0 criar Usuário Local */
                localStorage.setItem('trpVersao',versao_atual);
                localStorage.setItem('trpGrupo','Internauta');
            }
        }
    },500);
}

export {atualizaVariaveisLocais};