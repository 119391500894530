import React from 'react';
import styled from 'styled-components';
import {BrowserRouter, Routes, Route} from 'react-router-dom';


import SubTitulo from './componentes/SubTitulo/index';

/* Paginas */
import PaginaHome from './paginas/paginaHome';
import PaginaEditorTexto from './paginas/paginaEditorTexto';
import PaginaConfiguracao from './paginas/paginaConfiguracao';
import PaginaPadrao from './paginas/paginaPadrao';
import PaginaRelatorioDinamico from './paginas/paginaRelatorioDinamico';
import PaginaIndiceGrupo from './paginas/paginaIndiceGrupo';
import PaginaRelatorio from './paginas/paginaRelatorio';
import PaginaErro404 from './paginas/paginaErro404';
import PaginaTeste from './paginas/paginaTeste';
import PaginaIndiceBlog from './paginas/paginaIndiceBlog';
import PaginaBlog from './paginas/paginaBlog';
import PaginaTabelaPeriodos from './paginas/paginaTabelaPeriodos';
import PaginaPesquisa from './paginas/paginaPesquisa';
import PaginaPeriodoAmd from './paginas/paginaPeriodoAmd';
import PaginaProrrogacao from './paginas/paginaProrrogacao/index';
import AjudaEditorDeTexto from './paginas/paginaAjudaEditorDeTexto';

/* Temas */
import {ThemeProvider} from 'styled-components';
import {temaAzul,temaDivertido,temaLaranja,temaLilas,temaVerde} from './styles/temas';


const EstiloConteiner = styled.div`
  display: table;
  width: 100%;
`;


export default function AppRouters(){
  function pegarTema (nome) {
    const tema = {
      "azul":temaAzul,
      "divertido":temaDivertido,
      "laranja":temaLaranja,
      "lilas":temaLilas,
      "verde":temaVerde
    }
    var resultado = tema[nome];
    if(resultado === undefined){
        return temaLaranja
    }else{
        return tema[nome];
    }
  };

  


  

  return(
      <BrowserRouter>
        <ThemeProvider theme={pegarTema(localStorage.getItem('trpTema'))}>
          <Routes>
            <Route path='/' element={<PaginaPadrao/>}>
              <Route index element={<><SubTitulo texto="Home"/><PaginaHome/></>}/>
              <Route path='editortexto' element={<><SubTitulo texto="Editor de Texto"/><PaginaEditorTexto/></>}/>
              <Route path='configuracao' element={<><SubTitulo texto="Configuração"/><PaginaConfiguracao/></>}/>
              <Route path='teste' element={<EstiloConteiner><SubTitulo texto="Teste"/><PaginaTeste/></EstiloConteiner>}/>
              <Route path='relatorios/:id' element={<><SubTitulo texto="Relatório Dinâmico"/><PaginaRelatorioDinamico/></>}/>
              <Route path='conversao/periodos' element={<><SubTitulo texto="Tabela de conversão entre períodos"/><PaginaTabelaPeriodos/></>}/>
              <Route path='indice/:id' element={<PaginaIndiceGrupo/>}/>
              <Route path='indice/relatorio/:id' element={<PaginaRelatorio/>}/>
              <Route path='blog/:id' element={<PaginaIndiceBlog/>}/>
              <Route path='blog/assunto/:id' element={<PaginaBlog/>}/>
              <Route path='pesquisa' element={<><SubTitulo texto="Pesquisa"/><PaginaPesquisa/></>}/>
              <Route path='periodoamd' element={<><SubTitulo texto="Período Tempo"/><PaginaPeriodoAmd/></>}/>
              <Route path='prorrogacao' element={<><SubTitulo texto="Prorrogação de Prazo"/><PaginaProrrogacao/></>}/>
              <Route path='*' element={<><SubTitulo texto="Página não encotrada"/><PaginaErro404/></>}/>
              <Route path='ajuda/editor_de_texto' element={<><SubTitulo texto="Ajuda do Editor de Texto"/><AjudaEditorDeTexto/></>}/>
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
  ); 
}

