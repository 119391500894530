import React from "react";
import styled from 'styled-components';
/* import {ImCheckboxChecked,ImCheckboxUnchecked} from 'react-icons/im'; */
/* import Tags from 'componentes/layout/tags/index'; */

const EstiloProrrogacao = styled.div`
    float: left;
    padding: 20px 20px 30px 20px;
    margin: 0px 30px 30px 30px;
    background-color: ${({theme}) => theme.tmCorpo};
    display: table; 

    p{
        font-size: 16px;
    }

    label{
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
    }

    input{
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 30px 0px;
    }

    #ipt-resultado{
        width: 300px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        img{
            max-width: 70%;
            max-height: 25%;
        } 
    }

    @media (min-width: 481px){
        img{
            max-width: 25%;
            max-height: 25%;
        } 

    }
`;

export default function PaginaProrrogacao () {

    
    
    setTimeout(function(){
        
        var botao = document.getElementById("btn-calcular");
        botao.onclick = calculaNovaData;
        var hoje = new Date();
        var dia = String(hoje.getDate()).padStart(2, '0');
        var mes = String(hoje.getMonth() + 1).padStart(2, '0');
        var ano = hoje.getFullYear();
        var data_atual =  ano+"-"+mes+"-"+dia

        document.getElementById("ipt-dt-inicio").value = data_atual;
        document.getElementById("ipt-int-dias").value = 15;

    },500);

    function calculaNovaData(){
        const dia_semana_extenso = ["Domingo","Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
        var data = new Date(document.getElementById("ipt-dt-inicio").value);
        const dias = parseInt(document.getElementById("ipt-int-dias").value);
        data.setDate(data.getDate() + dias);
        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        var dia_da_semana = dia_semana_extenso[parseInt(data.getDay())];
        var nova_data =  dia+"-"+mes+"-"+ano+" - "+dia_da_semana;
        console.log(nova_data);
        document.getElementById("ipt-resultado").value = nova_data;
    }
      
    
    
    return(
        <EstiloProrrogacao id='container-pagina-preiodo-amd'>
            <label><b>Contado a partir de: </b></label><br/>
            <input id="ipt-dt-inicio" type="date"></input><br/>
            <label><b>Número de dias a somar: </b></label><br/>
            <input id="ipt-int-dias" type="number"></input><br/>
            <label><b>Resultado: </b></label><br/>
            <input id="ipt-resultado"></input><br/>
            <button id="btn-calcular" >Calcular</button>
        </EstiloProrrogacao>
    );
};