
import relatorios from '../../json/relatorios.json';
import { retornaIndiceDoGrupo } from '../../js/dadosLocais';
import ConteudoPagina from '../../componentes/ConteudoPagina/index';

export default function PaginaIndiceGrupo() {
    // js/dadosLocais.js > retornaIndiceDoGrupo() onde pega o indice do grupo
    const relatorio = relatorios.find((relatorio) => {
        return relatorio.id === retornaIndiceDoGrupo(localStorage['trpGrupo']);
    });

    return(
        <ConteudoPagina titulo={relatorio.titulo} texto={relatorio.codigo} tags={relatorio.tags}/>
    );
};


