import Cabecalho from '../../componentes/Cabecalho/index';
import Rodape from '../../componentes/Rodape';
import { ReiniciarEstilo } from '../../styles/reiniciar';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Menu from '../../componentes/Menu/index';

const EstiloPaginaPrincipal = styled.main`

    @media (min-width: 320px) and (max-width: 480px) {
    display:table;
    background-color: ${({theme}) => theme.tmCorpo};
    width: 70%;
    /* min-height: 580px; */
    float: left; 
    margin: 0px 0px 0px 0px;
    padding: 0px 0px;
    display: table;
    /* background-color: #FFEA20; */

    }

    @media (min-width: 481px){
        display:table;
        background-color: ${({theme}) => theme.tmCorpo};
        width: 100%;
        /* min-height: 580px; */
        float: left; 
        margin: 0px 0px 0px 0px;
        padding: 0px 0px;
        display: table;
        /* background-color: #7B2869; */
    }

`;


export default function PaginaPadrao(){
    return(
        <>
            <ReiniciarEstilo/>
            <EstiloPaginaPrincipal id="pagina-padrao">
                <Cabecalho texto ='Área de Trampo'/>
                <Menu/>
                <Outlet/>
                <Rodape/> 
            </EstiloPaginaPrincipal>
        </>
    )
}