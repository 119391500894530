import { tratamentoNomeCampo } from '../../js/convesoes';
import styled from 'styled-components';

const EstiloLista = styled.div`
    padding: 40px 0px 0px 0px ;

    label{
        font-weight: bolder;
        padding: 0px 0px 0px 0px ;
        color:var()
    }

    select{
        margin: 5px;
        background-color: ${({theme}) => theme.tmEntradaDados};
        width: 150px;
        padding: 5px 0px 0px 0px ;
    }

    
`;

interface ListaProps {
    nome:string;
    itens:string[];
    atual:number;
}

const Lista  = ({nome,itens,atual}:ListaProps) => {
    return(
        <EstiloLista className='lista'>
            <label>{nome}</label><br></br>
            <select className='lista-opcoes' name={nome} id={`lst-${tratamentoNomeCampo(nome)}`} defaultValue={atual}>
                {itens.map((item) => (
                    <option key={`lst-${tratamentoNomeCampo(nome)}-${item}`} value={item} id={`lst-${tratamentoNomeCampo(nome)}-${item}`} >{item}</option>
                ))}
            </select>
        </EstiloLista>
    );
};

export default Lista;