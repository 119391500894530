import React from "react";
import styled from 'styled-components';
import {GiFalling} from 'react-icons/gi';
import {useNavigate} from 'react-router-dom';
import {FaArrowAltCircleLeft } from "react-icons/fa";



const EstiloPaginaErro = styled.div`

    background-color:${({theme}) => theme.tmErro};
    
    width: 80%;
    height: 100%;
    align-items: center; 
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    border-radius: 10px;
    font-family: 'Press Start 2P', cursive;
    margin: 0px auto 30px auto;
    padding: 10px 30px 20px 30px;

    #imgQueda{
        padding: 0px 0px 0px 0px;
        margin: 0px 20px 20px 20px;
        width: 250px;
        height: 250px;
        color: white;
        background-color: transparent;

    }
    
    #erro404{
        color: white;
        font-family: 'Press Start 2P', cursive;
        margin: 20px 20px 25px 20px;
        font-size: 30px;
        text-align: center;
        line-height: 40px;
        }
    
    .botao{
        border:0px;
        font-family: 'Press Start 2P', cursive;
        font-size: 20px;
        margin: 10px 0px 0px 0px;
        padding: 15px 15px;
        background-color: white;
        color: ${({theme}) => theme.tmErro};
        border-radius: 10px;
        float: right;
        line-height: 30px;
    }
`;

export default function PaginaErro404(){
    const navigate = useNavigate();
    return(
      <EstiloPaginaErro>
        <GiFalling id="imgQueda"/>
        <p id="erro404">Erro 404: Caminho não encontrado</p>
        <button className='botao' onClick={()=> navigate('/')}><FaArrowAltCircleLeft/>{' Página Inicial'}</button>
      </EstiloPaginaErro>
    );
  }
