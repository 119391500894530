import { AscIIParaString, StringParaAscII } from './convesoes';

function json_para_string(dados){
    return JSON.stringify(dados);
}

function string_para_json(dados){
    return JSON.parse(dados);
}

function enviaSessioStorage(nome,dados){
    /* convertendo para ASCII antes de salvar */
    const texto = StringParaAscII(dados);
    sessionStorage.setItem(nome,texto);
}

function pegaSessioStorage(nome){
    /* convertendo para ASCII antes de salvar */
    const texto = sessionStorage.getItem(nome);
    return AscIIParaString(texto);
}

function retornaIndiceDoGrupo(nome){
    const grupo = {
        "Internauta":0,
        "Direito":500,
        "SITE Trampo":800,
        "Design Gráfico":6000,
        "Programador Python":7000,
        "Web Design":8000,
        "Publicidade":9000,
        "Programador JavaScript":10000
    }
    var resultado = grupo[nome];
    if(resultado === undefined){
        return 0
    }else{
        return grupo[nome]
    }

}


export {json_para_string,string_para_json,enviaSessioStorage,pegaSessioStorage,retornaIndiceDoGrupo}