import React from "react";
import styled from 'styled-components';
import { atualizaVariaveisLocais } from '../../js/versao';
import Tags from '../../componentes/Tags/index';

const EstiloPaginaPesquisa = styled.section`
    float: left;
    padding: 20px 20px 30px 20px;
    margin: 0px 30px 30px 30px;
    background-color: ${({theme}) => theme.tmCorpo};
    display: table; 

    p{
        font-size: 16px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        img{
            max-width: 70%;
            max-height: 25%;
        } 

    }

    @media (min-width: 481px){
        img{
            max-width: 25%;
            max-height: 25%;
        } 
    }
`;

export default function PaginaPesquisa () {
    
    var versao_local = localStorage.getItem('trpVersao');
    atualizaVariaveisLocais(versao_local);

    return(
        <EstiloPaginaPesquisa id='container-pagina-home'>
            <Tags lista={["pesquisa", "feedback", "melhoria"]}/>
        </EstiloPaginaPesquisa>
    );
};

