import React from "react";
import styled from 'styled-components';
import Tags from '../../componentes/Tags/index';

const EstiloTabelaPeriodos = styled.section`
    width: fit-content;

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: fit-content;
        margin: 20px 20px 20px 20px;

        td, th{
            border: 2px solid #000000;
            text-align: center;
            padding: 8px;
            font-size: 12px;
        }
    }


    #TM{
        background: #FFEC8B;
    }

    #TB{
        background: #9ACD32;
    }

    #TT{
        background: #87CEFF;
    }

    #TQ{
        background: #FF6EB4;
    }

    #N1,#N3,#N5,#N7,#N9,#N11{
        background: #EEE8AA;
    }
    #N2,#N4,#N6,#N8,#N10,#N12{
        background: #FFFFE0;
    }

    #M1,#M3,#M5,#M7,#M9,#M11{
        background: #EEE8AA;
    }
    #M2,#M4,#M6,#M8,#M10,#M12{
        background: #FFFFE0;
    }

    #B1,#B3,#B5{
        background: #C1CDC1;
    }
    #B2,#B4,#B6{
        background: #F0FFF0;
    }

    #T1,#T3{
        background: #9AC0CD;
    }
    #T2,#T4{
        background: #BFEFFF;
    }

    #Q1,#Q3{
        background: #CDC1C5;
    }

    #Q2{
        background: #FFF0F5;
    }
`;

export default function PaginaTabelaPeriodos () {

    return(
        <EstiloTabelaPeriodos id='container-pagina-tabela-periodos'>
            <table>
                <tr>
                    <th colspan="2" id="TM">Mês</th>
                    <th id="TB">Bimestre</th>
                    <th id="TT">Trimestre</th>
                    <th id="TQ">Quadrimestre</th>
                </tr>
                <tr>
                    <td id="N1">01</td>
                    <td id="M1">Janeiro</td>
                    <td id="B1" rowspan="2">01</td>
                    <td id="T1" rowspan="3">01</td>
                    <td id="Q1" rowspan="4">01</td>
                </tr>
                <tr>
                    <td id="N2">02</td>
                    <td id="M2">Fevereiro</td>
                </tr>
                <tr>
                    <td id="N3">03</td>
                    <td id="M3">Março</td>
                    <td id="B2" rowspan="2">02</td>
                </tr>
                <tr>
                    <td id="N4">04</td>
                    <td id="M4">Abril</td>
                    <td id="T2" rowspan="3">02</td>
                </tr>
                <tr>
                    <td id="N5">05</td>
                    <td id="M5">Maio</td>
                    <td id="B3" rowspan="2">03</td>
                    <td id="Q2" rowspan="4">02</td>
                </tr>
                <tr>
                    <td id="N6">06</td>
                    <td id="M6">Junho</td>
                </tr>
                <tr>
                    <td id="N7">07</td>
                    <td id="M7">Julho</td>
                    <td id="B4" rowspan="2">04</td>
                    <td id="T3" rowspan="3">03</td>
                </tr>
                <tr>
                    <td id="N8">08</td>
                    <td id="M8">Agosto</td>
                </tr>
                <tr>
                    <td id="N9">09</td>
                    <td id="M9">Setembro</td>
                    <td id="B5" rowspan="2">05</td>
                    <td id="Q3" rowspan="4">03</td>
                </tr>
                <tr>
                    <td id="N10">10</td>
                    <td id="M10">Outubro</td>
                    <td id="T4" rowspan="3">04</td>
                </tr>
                <tr>
                    <td id="N11">11</td>
                    <td id="M11">Novembro</td>
                    <td id="B6" rowspan="2">06</td>
                </tr>
                <tr>
                    <td id="N12">12</td>
                    <td id="M12">Dezembro</td>
                </tr>
            </table>
            <Tags lista={["mês", "bimestre", "trimestre", "quadrimestre", "blog"]}/>
        </EstiloTabelaPeriodos>
    );
};

