import styled from 'styled-components';
import {tamanho_fonte} from "../../styles/variaveis";

const EstiloCabecalho = styled.header`
    
    width: 100%;  
    height: 120px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;

    float: left;


    background-size: 60px 60px;
    background-position: left;
    background-color: ${({theme}) => theme.tmCabecalhoFundo};
    background-image: url(${({theme}) => theme.tmLogo}); 
    background-repeat: no-repeat;
    background-position-x: 10px;
  
    h1{
        margin: 30px 0px 0px 100px;
        padding: 0px 0px 0px 0px;
        font-size: ${tamanho_fonte}px;
        background-color: transparent;
        float: left;

        a{
            text-decoration: none;
            color: ${({theme}) => theme.tmCabecalhoFonte};
        }
    }



`;

interface CabecalhoProps{
    texto:string;
}

const Cabecalho  = ({texto}:CabecalhoProps) => {
    return(
        <EstiloCabecalho id="cabecalho">
            <h1><a href="/">{texto}</a></h1>
        </EstiloCabecalho>
    );
};

export default Cabecalho;