import styled from 'styled-components';
import Lista from '../../componentes/Lista/index';
import { atualizaVariaveisLocais } from '../../js/versao';
import Tags from '../../componentes/Tags/index';
import { useState } from 'react';



const EstiloPaginaConfiguracao = styled.section`
    min-height: 500px;
    background-color: ${({theme}) => theme.tmCorpo};
    margin: 0px auto 0px auto;
    padding-bottom: 40px;

    .formulario{
        background-color:${({theme}) => theme.tmCorpo};
        width: 5%;
        display: table;
        margin: 0px auto 0px auto;
        padding: 15px 0px 15px 0px;

    }
`;

const EstiloBotao = styled.button`
    border: 4px solid #000000;
    border-radius: 10px;
    font-weight:700;
    font-size:18px;
    transition: .3s;
    border:none;
    color:${({theme}) => theme.tmBotaoFont};
    float:right;
    background-color: ${({theme}) => theme.tmBotao};
    padding: 20px;
    /* margin: $margemCampo; */
    padding: 15px 15px 15px 15px;
    margin: 30px 0px 30px 0px; 

    &:hover {
        border: none;
        color: ${({theme}) => theme.tmBotaoHoverFont};
        transition: .3s;
        background-color: ${({theme}) => theme.tmBotaoHover};
        cursor:pointer;
    }

    &:active {
        transform: scale(0.9);
        transition: all 0.5s;
        color: ${({theme}) => theme.tmBotaoActiveFont};
        background-color: ${({theme}) => theme.tnBotaoActive};
    }  
    
`;


export default function PaginaConfiguracao () {
    const temas = ['azul','laranja','lilas','verde'];
    const grupos = ['Programador Python','Programador JavaScript','Internauta','Design Gráfico','Direito','Web Design', 'Publicidade'];
    let temaAtual = localStorage.getItem('trpTema');
    let grupoAtual = localStorage.getItem('trpGrupo');
    const [getTema,setTema] = useState("");
    const [getGrupo,setGrupo] = useState("");

    setTimeout(function(){
        var versao_local = localStorage.getItem('trpVersao');
        atualizaVariaveisLocais(versao_local);
        setTema(localStorage.getItem('trpTema'));
        setGrupo(localStorage.getItem('trpGrupo'));
        document.getElementById('lst-tema').value = getTema;
        document.getElementById('lst-grupo').value = getGrupo;
    },500);
    
    function atualizarConfiguracao(){
        localStorage.setItem('trpGrupo',document.getElementById('lst-grupo').value);
        var campoTema = document.getElementById('lst-tema');
        localStorage.setItem('trpTema', campoTema[campoTema.selectedIndex].value);
        var campoGrupo = document.getElementById('lst-grupo');
        localStorage.setItem('trpGrupo', campoGrupo[campoGrupo.selectedIndex].value);
        window.location.reload();
    }

    return(
        <EstiloPaginaConfiguracao id='container-pagina-configuracao'>
            <div className='formulario'>
                <b><label id='lbl-versao'>Versão:  {localStorage.getItem('trpVersao')}</label></b>
                <Lista nome="Tema" itens={temas} atual={temaAtual}/>
                <Lista nome="Grupo" itens={grupos} atual={grupoAtual}/>
                <EstiloBotao id="btn-salvar" onClick={atualizarConfiguracao}>Salvar</EstiloBotao>
            </div>
            <Tags lista={["configuração", "tema de cores", "personalização", "grupo de trabalho", "versão do site"]}/>
        </EstiloPaginaConfiguracao>
    );
};
