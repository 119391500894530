import {
    azul_logo,

    azul_Pagina_Titulo,
    azul_Pagina_SubTitulo,
    azul_Pagina_Fundo,

    azul_ajuda,

    azul_EntradaDados,
    azul_EntradaDadosFonte,
    
    azul_CabecalhoFundo, 
    azul_CabecalhoFonte,
    
    azul_SubTituloFundo, 
    azul_SubTituloFonte,
    azul_SubTituloHover,

    azul_MenuFundo, 
    azul_MenuFonte,
    
    azul_RodapeFundo,
    azul_RodapeFonte,
    
    azul_Corpo,
    azul_Formulario,  
    
    azul_Grupo, 
    azul_TituloGrupo,
    azul_TituloGrupoFonte,

    azul_GrupoBotoes, 
    azul_TituloGrupoBotoesFonte,

    azul_SubGrupo,
    azul_Radio,
    
    azul_Menu,        
    azul_SubMenu,
      
    azul_Botao, 
    azul_BotaoFont,    
    azul_BotaoHover, 
    azul_BotaoHoverFont, 
    azul_BotaoActive, 
     
    azul_Erro,       
    azul_Ok,            
            
    azul_Alerta,
    
    azul_Informativo, 
    azul_Link,
    azul_Imagem,

    laranja_logo,

    laranja_Pagina_Titulo,
    laranja_Pagina_SubTitulo,
    laranja_Pagina_Fundo,

    laranja_ajuda,

    laranja_EntradaDados,
    laranja_EntradaDadosFonte,
    
    laranja_CabecalhoFundo, 
    laranja_CabecalhoFonte,
    
    laranja_SubTituloFundo, 
    laranja_SubTituloFonte,
    laranja_SubTituloHover,

    laranja_MenuFundo, 
    laranja_MenuFonte,

    laranja_RodapeFundo,
    laranja_RodapeFonte,
    
    laranja_Corpo,
    laranja_Formulario,  
    
    laranja_Grupo, 
    laranja_TituloGrupo,
    laranja_TituloGrupoFonte,

    laranja_GrupoBotoes, 
    laranja_TituloGrupoBotoesFonte,

    laranja_SubGrupo,
    laranja_Radio,
    
    laranja_Menu,        
    laranja_SubMenu,
      
    laranja_Botao, 
    laranja_BotaoFont,    
    laranja_BotaoHover, 
    laranja_BotaoHoverFont, 
    laranja_BotaoActive,
     
    laranja_Erro,       
    laranja_Ok,            
            
    laranja_Alerta,
    
    laranja_Informativo, 
    laranja_Link,
    laranja_Imagem,

    verde_logo,

    verde_Pagina_Titulo,
    verde_Pagina_SubTitulo,
    verde_Pagina_Fundo,
    
    verde_ajuda,

    verde_EntradaDados,
    verde_EntradaDadosFonte,
    
    verde_CabecalhoFundo, 
    verde_CabecalhoFonte,
    
    verde_SubTituloFundo, 
    verde_SubTituloFonte,
    verde_SubTituloHover,
    
    verde_MenuFundo, 
    verde_MenuFonte,
    
    verde_RodapeFundo,
    verde_RodapeFonte,
    
    verde_Corpo,
    verde_Formulario,  
    
    verde_Grupo,
    verde_TituloGrupo,
    verde_TituloGrupoFonte,

    verde_GrupoBotoes, 
    verde_TituloGrupoBotoesFonte,

    verde_SubGrupo,
    verde_Radio,
    
    verde_Menu,        
    verde_SubMenu,
      
    verde_Botao, 
    verde_BotaoFont,    
    verde_BotaoHover, 
    verde_BotaoHoverFont, 
    verde_BotaoActive,
     
    verde_Erro,       
    verde_Ok,            
            
    verde_Alerta,
    
    verde_Informativo, 
    verde_Link,
    verde_Imagem,

    lilas_logo,

    lilas_Pagina_Titulo,
    lilas_Pagina_SubTitulo,
    lilas_Pagina_Fundo,

    lilas_ajuda,

    lilas_EntradaDados,
    lilas_EntradaDadosFonte,
    
    lilas_CabecalhoFundo, 
    lilas_CabecalhoFonte,
    
    lilas_SubTituloFundo, 
    lilas_SubTituloFonte,
    lilas_SubTituloHover,

    lilas_MenuFundo, 
    lilas_MenuFonte,
    
    lilas_RodapeFundo,
    lilas_RodapeFonte,
    
    lilas_Corpo,
    lilas_Formulario,  
    
    lilas_Grupo, 
    lilas_TituloGrupo,
    lilas_TituloGrupoFonte,

    lilas_GrupoBotoes, 
    lilas_TituloGrupoBotoesFonte,

    lilas_SubGrupo,
    lilas_Radio,
    
    lilas_Menu,        
    lilas_SubMenu,
      
    lilas_Botao, 
    lilas_BotaoFont,    
    lilas_BotaoHover, 
    lilas_BotaoHoverFont, 
    lilas_BotaoActive, 
     
    lilas_Erro,       
    lilas_Ok,            
            
    lilas_Alerta,
    
    lilas_Informativo, 
    lilas_Link,
    lilas_Imagem,

    divertido_logo,

    divertido_Pagina_Titulo,
    divertido_Pagina_SubTitulo,
    divertido_Pagina_Fundo,

    divertido_ajuda,

    divertido_EntradaDados,
    divertido_EntradaDadosFonte,
    
    divertido_CabecalhoFundo, 
    divertido_CabecalhoFonte,
    
    divertido_SubTituloFundo, 
    divertido_SubTituloFonte,
    divertido_SubTituloHover,

    divertido_MenuFundo, 
    divertido_MenuFonte,
    
    divertido_RodapeFundo,
    divertido_RodapeFonte,
    
    divertido_Corpo,
    divertido_Formulario,  
    
    divertido_Grupo, 
    divertido_TituloGrupo,
    divertido_TituloGrupoFonte,

    divertido_GrupoBotoes, 
    divertido_TituloGrupoBotoesFonte,

    divertido_SubGrupo,
    divertido_Radio,
    
    divertido_Menu,        
    divertido_SubMenu,
      
    divertido_Botao, 
    divertido_BotaoFont,    
    divertido_BotaoHover, 
    divertido_BotaoHoverFont, 
    divertido_BotaoActive, 
     
    divertido_Erro,       
    divertido_Ok,            
            
    divertido_Alerta,
    
    divertido_Informativo, 
    divertido_Link,
    divertido_Imagem,

    

} from "./variaveis";

export const temaAzul = {
    tmLogo: azul_logo,

    tmPaginaTitulo: azul_Pagina_Titulo,
    tmPaginaSubTitulo: azul_Pagina_SubTitulo,
    tmPaginaFundo: azul_Pagina_Fundo,

    tmAjuda: azul_ajuda,

    tmEntradaDados: azul_EntradaDados,
    tmEntradaDadosFonte: azul_EntradaDadosFonte,
    
    tmCabecalhoFundo: azul_CabecalhoFundo, 
    tmCabecalhoFonte: azul_CabecalhoFonte,
    
    tmSubTituloFundo: azul_SubTituloFundo, 
    tmSubTituloFonte: azul_SubTituloFonte,
    tmSubTituloHover: azul_SubTituloHover,

    tmMenuFundo: azul_MenuFundo, 
    tmMenuFonte: azul_MenuFonte,
    
    tmRodapeFundo: azul_RodapeFundo,
    tmRodapeFonte: azul_RodapeFonte,
    
    tmCorpo:  azul_Corpo,
    tmFormulario: azul_Formulario,  
    
    tmGrupo: azul_Grupo, 
    tmTituloGrupo: azul_TituloGrupo,
    tmTituloGrupoFonte: azul_TituloGrupoFonte,

    tmGrupoBotoes: azul_GrupoBotoes, 
    tmTituloGrupoBotoesFonte: azul_TituloGrupoBotoesFonte,

    tmSubGrupo: azul_SubGrupo,
    tmRadio: azul_Radio,
    
    tmMenu: azul_Menu,        
    tmSubMenu: azul_SubMenu,
      
    tmBotao: azul_Botao, 
    tmBotaoFont: azul_BotaoFont,    
    tmBotaoHover: azul_BotaoHover, 
    tmBotaoHoverFont: azul_BotaoHoverFont, 
    tmBotaoActive: azul_BotaoActive, 
     
    tmErro: azul_Erro,       
    tmOk: azul_Ok,            
            
    tmAlerta: azul_Alerta,
    
    tmInformativo: azul_Informativo, 
    tmLink: azul_Link,
    tmImagem: azul_Imagem,
};

export const temaLaranja = {

    tmLogo: laranja_logo,

    tmPaginaTitulo: laranja_Pagina_Titulo,
    tmPaginaSubTitulo: laranja_Pagina_SubTitulo,
    tmPaginaFundo: laranja_Pagina_Fundo,

    tmAjuda: laranja_ajuda,

    tmEntradaDados: laranja_EntradaDados,
    tmEntradaDadosFonte: laranja_EntradaDadosFonte,
    
    tmCabecalhoFundo: laranja_CabecalhoFundo, 
    tmCabecalhoFonte: laranja_CabecalhoFonte,
    
    tmSubTituloFundo: laranja_SubTituloFundo, 
    tmSubTituloFonte: laranja_SubTituloFonte,
    tmSubTituloHover: laranja_SubTituloHover,

    tmMenuFundo: laranja_MenuFundo, 
    tmMenuFonte: laranja_MenuFonte,
    
    tmRodapeFundo: laranja_RodapeFundo,
    tmRodapeFonte: laranja_RodapeFonte,
    
    tmCorpo:  laranja_Corpo,
    tmFormulario: laranja_Formulario,  
    
    tmGrupo: laranja_Grupo, 
    tmTituloGrupo: laranja_TituloGrupo,
    tmTituloGrupoFonte: laranja_TituloGrupoFonte,

    tmGrupoBotoes: laranja_GrupoBotoes, 
    tmTituloGrupoBotoesFonte: laranja_TituloGrupoBotoesFonte,

    tmSubGrupo: laranja_SubGrupo,
    tmRadio: laranja_Radio,
    
    tmMenu: laranja_Menu,        
    tmSubMenu: laranja_SubMenu,
      
    tmBotao: laranja_Botao, 
    tmBotaoFont: laranja_BotaoFont,    
    tmBotaoHover: laranja_BotaoHover,
    tmBotaoHoverFont: laranja_BotaoHoverFont,  
    tmBotaoActive: laranja_BotaoActive, 
     
    tmErro: laranja_Erro,       
    tmOk: laranja_Ok,            
            
    tmAlerta: laranja_Alerta,
    
    tmInformativo: laranja_Informativo, 
    tmLink: laranja_Link,
    tmImagem: laranja_Imagem,
};

export const temaVerde = {

    tmLogo: verde_logo,

    tmPaginaTitulo: verde_Pagina_Titulo,
    tmPaginaSubTitulo: verde_Pagina_SubTitulo,
    tmPaginaFundo: verde_Pagina_Fundo,

    tmAjuda: verde_ajuda,

    tmEntradaDados: verde_EntradaDados,
    tmEntradaDadosFonte: verde_EntradaDadosFonte,
    
    tmCabecalhoFundo: verde_CabecalhoFundo, 
    tmCabecalhoFonte: verde_CabecalhoFonte,
    
    tmSubTituloFundo: verde_SubTituloFundo, 
    tmSubTituloFonte: verde_SubTituloFonte,
    tmSubTituloHover: verde_SubTituloHover,

    tmMenuFundo: verde_MenuFundo, 
    tmMenuFonte: verde_MenuFonte,
    
    tmRodapeFundo: verde_RodapeFundo,
    tmRodapeFonte: verde_RodapeFonte,
    
    tmCorpo:  verde_Corpo,
    tmFormulario: verde_Formulario,  
    
    tmGrupo: verde_Grupo, 
    tmTituloGrupo: verde_TituloGrupo,
    tmTituloGrupoFonte: verde_TituloGrupoFonte,

    tmGrupoBotoes: verde_GrupoBotoes, 
    tmTituloGrupoBotoesFonte: verde_TituloGrupoBotoesFonte,
    
    tmSubGrupo: verde_SubGrupo,
    tmRadio: verde_Radio,
    
    tmMenu: verde_Menu,        
    tmSubMenu: verde_SubMenu,
      
    tmBotao: verde_Botao, 
    tmBotaoFont: verde_BotaoFont,    
    tmBotaoHover: verde_BotaoHover,  
    tmBotaoHoverFont: verde_BotaoHoverFont,
    tmBotaoActive: verde_BotaoActive, 
     
    tmErro: verde_Erro,       
    tmOk: verde_Ok,            
            
    tmAlerta: verde_Alerta,
    
    tmInformativo: verde_Informativo, 
    tmLink: verde_Link,
    tmImagem: verde_Imagem,
};



export const temaLilas = {
    tmLogo: lilas_logo,

    tmPaginaTitulo: lilas_Pagina_Titulo,
    tmPaginaSubTitulo: lilas_Pagina_SubTitulo,
    tmPaginaFundo: lilas_Pagina_Fundo,

    tmAjuda: lilas_ajuda,

    tmEntradaDados: lilas_EntradaDados,
    tmEntradaDadosFonte: lilas_EntradaDadosFonte,
    
    tmCabecalhoFundo: lilas_CabecalhoFundo, 
    tmCabecalhoFonte: lilas_CabecalhoFonte,
    
    tmSubTituloFundo: lilas_SubTituloFundo, 
    tmSubTituloFonte: lilas_SubTituloFonte,
    tmSubTituloHover: lilas_SubTituloHover,

    tmMenuFundo: lilas_MenuFundo, 
    tmMenuFonte: lilas_MenuFonte,
    
    tmRodapeFundo: lilas_RodapeFundo,
    tmRodapeFonte: lilas_RodapeFonte,
    
    tmCorpo:  lilas_Corpo,
    tmFormulario: lilas_Formulario,  
    
    tmGrupo: lilas_Grupo, 
    tmTituloGrupo: lilas_TituloGrupo,
    tmTituloGrupoFonte: lilas_TituloGrupoFonte,

    tmGrupoBotoes: lilas_GrupoBotoes, 
    tmTituloGrupoBotoesFonte: lilas_TituloGrupoBotoesFonte,

    tmSubGrupo: lilas_SubGrupo,
    tmRadio: lilas_Radio,
    
    tmMenu: lilas_Menu,        
    tmSubMenu: lilas_SubMenu,
      
    tmBotao: lilas_Botao, 
    tmBotaoFont: lilas_BotaoFont,    
    tmBotaoHover: lilas_BotaoHover, 
    tmBotaoHoverFont: lilas_BotaoHoverFont, 
    tmBotaoActive: lilas_BotaoActive, 
     
    tmErro: lilas_Erro,       
    tmOk: lilas_Ok,            
            
    tmAlerta: lilas_Alerta,
    
    tmInformativo: lilas_Informativo, 
    tmLink: lilas_Link,
    tmImagem: lilas_Imagem,
};

export const temaDivertido = {
    tmLogo: divertido_logo,

    tmPaginaTitulo: divertido_Pagina_Titulo,
    tmPaginaSubTitulo: divertido_Pagina_SubTitulo,
    tmPaginaFundo: divertido_Pagina_Fundo,

    tmAjuda: divertido_ajuda,

    tmEntradaDados: divertido_EntradaDados,
    tmEntradaDadosFonte: divertido_EntradaDadosFonte,
    
    tmCabecalhoFundo: divertido_CabecalhoFundo, 
    tmCabecalhoFonte: divertido_CabecalhoFonte,
    
    tmSubTituloFundo: divertido_SubTituloFundo, 
    tmSubTituloFonte: divertido_SubTituloFonte,
    tmSubTituloHover: divertido_SubTituloHover,

    tmMenuFundo: divertido_MenuFundo, 
    tmMenuFonte: divertido_MenuFonte,
    
    tmRodapeFundo: divertido_RodapeFundo,
    tmRodapeFonte: divertido_RodapeFonte,
    
    tmCorpo:  divertido_Corpo,
    tmFormulario: divertido_Formulario,  
    
    tmGrupo: divertido_Grupo, 
    tmTituloGrupo: divertido_TituloGrupo,
    tmTituloGrupoFonte: divertido_TituloGrupoFonte,

    tmGrupoBotoes: divertido_GrupoBotoes, 
    tmTituloGrupoBotoesFonte: divertido_TituloGrupoBotoesFonte,

    tmSubGrupo: divertido_SubGrupo,
    tmRadio: divertido_Radio,
    
    tmMenu: divertido_Menu,        
    tmSubMenu: divertido_SubMenu,
      
    tmBotao: divertido_Botao, 
    tmBotaoFont: divertido_BotaoFont,    
    tmBotaoHover: divertido_BotaoHover, 
    tmBotaoHoverFont: divertido_BotaoHoverFont, 
    tmBotaoActive: divertido_BotaoActive, 
     
    tmErro: divertido_Erro,       
    tmOk: divertido_Ok,            
            
    tmAlerta: divertido_Alerta,
    
    tmInformativo: divertido_Informativo, 
    tmLink: divertido_Link,
    tmImagem: divertido_Imagem,
};

