import React from "react";
import styled from 'styled-components';
/* import {ImCheckboxChecked,ImCheckboxUnchecked} from 'react-icons/im'; */
/* import Tags from 'componentes/layout/tags/index'; */

const EstiloPeriodoAmd = styled.div`
    float: left;
    padding: 20px 20px 30px 20px;
    margin: 0px 30px 30px 30px;
    background-color: ${({theme}) => theme.tmCorpo};
    display: table; 

    p{
        font-size: 16px;
    }

    label{
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
    }

    input{
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 30px 0px;
    }

    #ipt-resultado{
        width: 300px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        img{
            max-width: 70%;
            max-height: 25%;
        } 
    }

    @media (min-width: 481px){
        img{
            max-width: 25%;
            max-height: 25%;
        } 

    }
`;

export default function PaginaPeriodoAmd () {

    
    
    setTimeout(function(){
        
        var hoje = new Date();
        var dia = String(hoje.getDate()).padStart(2, '0');
        var mes = String(hoje.getMonth() + 1).padStart(2, '0');
        var ano = hoje.getFullYear();

        var data_atual =  ano+"-"+mes+"-"+ dia;
        var default_final = data_atual;
        document.getElementById("ipt-dt-inicio").value = "2023-01-01";
        document.getElementById("ipt-dt-final").value = default_final;

    },500);


    function singular_plural(valor, singular, plural){
        if(valor > 1){
          return plural
      }else{
          return singular
      }
    }
    
    function calculaDiferenca(){
        const data_inicio = new Date(document.getElementById("ipt-dt-inicio").value);
        const data_final = new Date(document.getElementById("ipt-dt-final").value);
        const iptresultado = document.getElementById("ipt-resultado");
        var resultado = data_final - data_inicio;
    
        var anos = 0;
        var meses = 0;
        var dias = resultado / (1000 * 60 * 60 * 24);
        var resto = 0;
        
        // Resultado Negativo
        if(dias < 0){
            iptresultado.value = "A data final é menor que a data inicial";
        }else{
          // Um ano ou mais
          if (dias >= 365){
             anos = parseInt(dias / 365);
             resto = parseInt(dias % 365);

             meses = parseInt(resto / 30);
             dias = parseInt(resto % 30);

             iptresultado.value = " " + anos + singular_plural(anos," ano"," anos") + " " + meses + singular_plural(meses," mês"," meses") + " " + dias + singular_plural(dias," dia"," dias")
          }else if(dias < 365 && dias >= 30){
             meses = parseInt(dias / 30);
             dias = parseInt(dias % 30);
             
             iptresultado.value = meses + singular_plural(meses," mês"," meses") + " " + dias + singular_plural(dias," dia"," dias");
          }else {
            iptresultado.value = dias + singular_plural(dias," dia"," dias");
          }
        } 
    }
    
    return(
        <EstiloPeriodoAmd id='container-pagina-preiodo-amd'>
            <label><b>Inicio: </b></label><br/>
            <input id="ipt-dt-inicio" type="date"></input><br/>
            <label><b>Final: </b></label><br/>
	        <input id="ipt-dt-final" type="date"></input><br/>
            <label><b>Resultado: </b></label><br/>
            <input id="ipt-resultado"></input><br/><br/>
            <button id="btn-calcular" onClick={calculaDiferenca}>Calcular</button>
        </EstiloPeriodoAmd>
    );
};