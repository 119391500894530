import ExpReg from './exp-reg';

const str_para_ascii = new Map();
str_para_ascii.set('!','0041');
str_para_ascii.set('"','0042');
str_para_ascii.set('#','0043');
str_para_ascii.set('$','0044');
str_para_ascii.set('%','0045');
str_para_ascii.set('&','0046');
str_para_ascii.set("'",'0047');
str_para_ascii.set('(','0050');
str_para_ascii.set(')','0051');
str_para_ascii.set('*','0052');
str_para_ascii.set('+','0053');
str_para_ascii.set(',','0054');
str_para_ascii.set('-','0055');
str_para_ascii.set('.','0056');
str_para_ascii.set('/','0057');
str_para_ascii.set('0','0060');
str_para_ascii.set('1','0061');
str_para_ascii.set('2','0062');
str_para_ascii.set('3','0063');
str_para_ascii.set('4','0064');
str_para_ascii.set('5','0065');
str_para_ascii.set('6','0066');
str_para_ascii.set('7','0067');
str_para_ascii.set('8','0070');
str_para_ascii.set('9','0071');
str_para_ascii.set(':','0072');
str_para_ascii.set(';','0073');
str_para_ascii.set('<','0074');
str_para_ascii.set('=','0075');
str_para_ascii.set('>','0076');
str_para_ascii.set('?','0077');
str_para_ascii.set('@','0100');
str_para_ascii.set('A','0101');
str_para_ascii.set('B','0102');
str_para_ascii.set('C','0103');
str_para_ascii.set('D','0104');
str_para_ascii.set('E','0105');
str_para_ascii.set('F','0106');
str_para_ascii.set('G','0107');
str_para_ascii.set('H','0110');
str_para_ascii.set('I','0111');
str_para_ascii.set('J','0112');
str_para_ascii.set('K','0113');
str_para_ascii.set('L','0114');
str_para_ascii.set('M','0115');
str_para_ascii.set('N','0116');
str_para_ascii.set('O','0117');
str_para_ascii.set('P','0120');
str_para_ascii.set('Q','0121');
str_para_ascii.set('R','0122');
str_para_ascii.set('S','0123');
str_para_ascii.set('T','0124');
str_para_ascii.set('U','0125');
str_para_ascii.set('V','0126');
str_para_ascii.set('W','0127');
str_para_ascii.set('X','0130');
str_para_ascii.set('Y','0131');
str_para_ascii.set('Z','0132');
str_para_ascii.set('[','0133');
str_para_ascii.set('\\','0134');
str_para_ascii.set(']','0135');
str_para_ascii.set('^','0136');
str_para_ascii.set('_','0137');
str_para_ascii.set('`','0140');
str_para_ascii.set('a','0141');
str_para_ascii.set('b','0142');
str_para_ascii.set('c','0143');
str_para_ascii.set('d','0144');
str_para_ascii.set('e','0145');
str_para_ascii.set('f','0146');
str_para_ascii.set('g','0147');
str_para_ascii.set('h','0150');
str_para_ascii.set('i','0151');
str_para_ascii.set('j','0152');
str_para_ascii.set('k','0153');
str_para_ascii.set('l','0154');
str_para_ascii.set('m','0155');
str_para_ascii.set('n','0156');
str_para_ascii.set('o','0157');
str_para_ascii.set('p','0160');
str_para_ascii.set('q','0161');
str_para_ascii.set('r','0162');
str_para_ascii.set('s','0163');
str_para_ascii.set('t','0164');
str_para_ascii.set('u','0165');
str_para_ascii.set('v','0166');
str_para_ascii.set('w','0167');
str_para_ascii.set('x','0170');
str_para_ascii.set('y','0171');
str_para_ascii.set('z','0172');
str_para_ascii.set('{','0173');
str_para_ascii.set('|','0174');
str_para_ascii.set('}','0175');
str_para_ascii.set('~','0176');
str_para_ascii.set('Ç','0200');
str_para_ascii.set('ü','0201');
str_para_ascii.set('é','0202');
str_para_ascii.set('â','0203');
str_para_ascii.set('ä','0204');
str_para_ascii.set('à','0205');
str_para_ascii.set('å','0206');
str_para_ascii.set('ç','0207');
str_para_ascii.set('ê','0210');
str_para_ascii.set('ë','0211');
str_para_ascii.set('è','0212');
str_para_ascii.set('ï','0213');
str_para_ascii.set('î','0214');
str_para_ascii.set('ì','0215');
str_para_ascii.set('Ä','0216');
str_para_ascii.set('Å','0217');
str_para_ascii.set('É','0220');
str_para_ascii.set('æ','0221');
str_para_ascii.set('Æ','0222');
str_para_ascii.set('ô','0223');
str_para_ascii.set('ö','0224');
str_para_ascii.set('ò','0225');
str_para_ascii.set('û','0226');
str_para_ascii.set('ù','0227');
str_para_ascii.set('ÿ','0230');
str_para_ascii.set('Ö','0231');
str_para_ascii.set('Ü','0232');
str_para_ascii.set('ø','0233');
str_para_ascii.set('£','0234');
str_para_ascii.set('Ø','0235');
str_para_ascii.set('×','0236');
str_para_ascii.set('ƒ','0237');
str_para_ascii.set('á','0240');
str_para_ascii.set('í','0241');
str_para_ascii.set('ó','0242');
str_para_ascii.set('ú','0243');
str_para_ascii.set('ñ','0244');
str_para_ascii.set('Ñ','0245');
str_para_ascii.set('ª','0246');
str_para_ascii.set('º','0247');
str_para_ascii.set('¿','0250');
str_para_ascii.set('®','0251');
str_para_ascii.set('¬','0252');
str_para_ascii.set('½','0253');
str_para_ascii.set('¼','0254');
str_para_ascii.set('¡','0255');
str_para_ascii.set('«','0256');
str_para_ascii.set('»','0257');
str_para_ascii.set('_','0260');
str_para_ascii.set('_','0261');
str_para_ascii.set('_','0262');
str_para_ascii.set('¦','0263');
str_para_ascii.set('¦','0264');
str_para_ascii.set('Á','0265');
str_para_ascii.set('Â','0266');
str_para_ascii.set('À','0267');
str_para_ascii.set('©','0270');
str_para_ascii.set('¦','0271');
str_para_ascii.set('¦','0272');
str_para_ascii.set('+','0273');
str_para_ascii.set('+','0274');
str_para_ascii.set('¢','0275');
str_para_ascii.set('¥','0276');
str_para_ascii.set('+','0277');
str_para_ascii.set('+','0300');
str_para_ascii.set('-','0301');
str_para_ascii.set('-','0302');
str_para_ascii.set('+','0303');
str_para_ascii.set('-','0304');
str_para_ascii.set('+','0305');
str_para_ascii.set('ã','0306');
str_para_ascii.set('Ã','0307');
str_para_ascii.set('+','0310');
str_para_ascii.set('+','0311');
str_para_ascii.set('-','0312');
str_para_ascii.set('-','0313');
str_para_ascii.set('¦','0314');
str_para_ascii.set('-','0315');
str_para_ascii.set('+','0316');
str_para_ascii.set('¤','0317');
str_para_ascii.set('ð','0320');
str_para_ascii.set('Ð','0321');
str_para_ascii.set('Ê','0322');
str_para_ascii.set('Ë','0323');
str_para_ascii.set('È','0324');
str_para_ascii.set('i','0325');
str_para_ascii.set('Í','0326');
str_para_ascii.set('Î','0327');
str_para_ascii.set('Ï','0330');
str_para_ascii.set('+','0331');
str_para_ascii.set('+','0332');
str_para_ascii.set('_','0333');
str_para_ascii.set('_','0334');
str_para_ascii.set('¦','0335');
str_para_ascii.set('Ì','0336');
str_para_ascii.set('_','0337');
str_para_ascii.set('Ó','0340');
str_para_ascii.set('ß','0341');
str_para_ascii.set('Ô','0342');
str_para_ascii.set('Ò','0343');
str_para_ascii.set('Õ','0344');
str_para_ascii.set('Õ','0345');
str_para_ascii.set('µ','0346');
str_para_ascii.set('Þ','0347');
str_para_ascii.set('Þ','0350');
str_para_ascii.set('Ú','0351');
str_para_ascii.set('Û','0352');
str_para_ascii.set('Ù','0353');
str_para_ascii.set('ý','0354');
str_para_ascii.set('Ý','0355');
str_para_ascii.set('¯','0356');
str_para_ascii.set('´','0357');
str_para_ascii.set(' ','0360');
str_para_ascii.set('±','0361');
str_para_ascii.set('_','0362');
str_para_ascii.set('¾','0363');
str_para_ascii.set('¶','0364');
str_para_ascii.set('§','0365');
str_para_ascii.set('÷','0366');
str_para_ascii.set('¸','0367');
str_para_ascii.set('°','0370');
str_para_ascii.set('¨','0371');
str_para_ascii.set('·','0372');
str_para_ascii.set('¹','0373');
str_para_ascii.set('³','0374');
str_para_ascii.set('²','0375');
str_para_ascii.set('_','0376');
str_para_ascii.set(' ','0377');

const ascii_para_str = new Map();
ascii_para_str.set('0042','"');
ascii_para_str.set('0041','!');
ascii_para_str.set('0043','#');
ascii_para_str.set('0044','$');
ascii_para_str.set('0045','%');
ascii_para_str.set('0046','&');
ascii_para_str.set('0047',"'");
ascii_para_str.set('0050','(');
ascii_para_str.set('0051',')');
ascii_para_str.set('0052','*');
ascii_para_str.set('0053','+');
ascii_para_str.set('0054',',');
ascii_para_str.set('0055','-');
ascii_para_str.set('0056','.');
ascii_para_str.set('0057','/');
ascii_para_str.set('0060','0');
ascii_para_str.set('0061','1');
ascii_para_str.set('0062','2');
ascii_para_str.set('0063','3');
ascii_para_str.set('0064','4');
ascii_para_str.set('0065','5');
ascii_para_str.set('0066','6');
ascii_para_str.set('0067','7');
ascii_para_str.set('0070','8');
ascii_para_str.set('0071','9');
ascii_para_str.set('0072',':');
ascii_para_str.set('0073',';');
ascii_para_str.set('0074','<');
ascii_para_str.set('0075','=');
ascii_para_str.set('0076','>');
ascii_para_str.set('0077','?');
ascii_para_str.set('0100','@');
ascii_para_str.set('0101','A');
ascii_para_str.set('0102','B');
ascii_para_str.set('0103','C');
ascii_para_str.set('0104','D');
ascii_para_str.set('0105','E');
ascii_para_str.set('0106','F');
ascii_para_str.set('0107','G');
ascii_para_str.set('0110','H');
ascii_para_str.set('0111','I');
ascii_para_str.set('0112','J');
ascii_para_str.set('0113','K');
ascii_para_str.set('0114','L');
ascii_para_str.set('0115','M');
ascii_para_str.set('0116','N');
ascii_para_str.set('0117','O');
ascii_para_str.set('0120','P');
ascii_para_str.set('0121','Q');
ascii_para_str.set('0122','R');
ascii_para_str.set('0123','S');
ascii_para_str.set('0124','T');
ascii_para_str.set('0125','U');
ascii_para_str.set('0126','V');
ascii_para_str.set('0127','W');
ascii_para_str.set('0130','X');
ascii_para_str.set('0131','Y');
ascii_para_str.set('0132','Z');
ascii_para_str.set('0133','[');
ascii_para_str.set('0134','\\');
ascii_para_str.set('0135',']');
ascii_para_str.set('0136','^');
ascii_para_str.set('0137','_');
ascii_para_str.set('0140','`');
ascii_para_str.set('0141','a');
ascii_para_str.set('0142','b');
ascii_para_str.set('0143','c');
ascii_para_str.set('0144','d');
ascii_para_str.set('0145','e');
ascii_para_str.set('0146','f');
ascii_para_str.set('0147','g');
ascii_para_str.set('0150','h');
ascii_para_str.set('0151','i');
ascii_para_str.set('0152','j');
ascii_para_str.set('0153','k');
ascii_para_str.set('0154','l');
ascii_para_str.set('0155','m');
ascii_para_str.set('0156','n');
ascii_para_str.set('0157','o');
ascii_para_str.set('0160','p');
ascii_para_str.set('0161','q');
ascii_para_str.set('0162','r');
ascii_para_str.set('0163','s');
ascii_para_str.set('0164','t');
ascii_para_str.set('0165','u');
ascii_para_str.set('0166','v');
ascii_para_str.set('0167','w');
ascii_para_str.set('0170','x');
ascii_para_str.set('0171','y');
ascii_para_str.set('0172','z');
ascii_para_str.set('0173','{');
ascii_para_str.set('0174','|');
ascii_para_str.set('0175','}');
ascii_para_str.set('0176','~');
ascii_para_str.set('0200','Ç');
ascii_para_str.set('0201','ü');
ascii_para_str.set('0202','é');
ascii_para_str.set('0203','â');
ascii_para_str.set('0204','ä');
ascii_para_str.set('0205','à');
ascii_para_str.set('0206','å');
ascii_para_str.set('0207','ç');
ascii_para_str.set('0210','ê');
ascii_para_str.set('0211','ë');
ascii_para_str.set('0212','è');
ascii_para_str.set('0213','ï');
ascii_para_str.set('0214','î');
ascii_para_str.set('0215','ì');
ascii_para_str.set('0216','Ä');
ascii_para_str.set('0217','Å');
ascii_para_str.set('0220','É');
ascii_para_str.set('0221','æ');
ascii_para_str.set('0222','Æ');
ascii_para_str.set('0223','ô');
ascii_para_str.set('0224','ö');
ascii_para_str.set('0225','ò');
ascii_para_str.set('0226','û');
ascii_para_str.set('0227','ù');
ascii_para_str.set('0230','ÿ');
ascii_para_str.set('0231','Ö');
ascii_para_str.set('0232','Ü');
ascii_para_str.set('0233','ø');
ascii_para_str.set('0234','£');
ascii_para_str.set('0235','Ø');
ascii_para_str.set('0236','×');
ascii_para_str.set('0237','ƒ');
ascii_para_str.set('0240','á');
ascii_para_str.set('0241','í');
ascii_para_str.set('0242','ó');
ascii_para_str.set('0243','ú');
ascii_para_str.set('0244','ñ');
ascii_para_str.set('0245','Ñ');
ascii_para_str.set('0246','ª');
ascii_para_str.set('0247','º');
ascii_para_str.set('0250','¿');
ascii_para_str.set('0251','®');
ascii_para_str.set('0252','¬');
ascii_para_str.set('0253','½');
ascii_para_str.set('0254','¼');
ascii_para_str.set('0255','¡');
ascii_para_str.set('0256','«');
ascii_para_str.set('0257','»');
ascii_para_str.set('0260','_');
ascii_para_str.set('0261','_');
ascii_para_str.set('0262','_');
ascii_para_str.set('0263','¦');
ascii_para_str.set('0264','¦');
ascii_para_str.set('0265','Á');
ascii_para_str.set('0266','Â');
ascii_para_str.set('0267','À');
ascii_para_str.set('0270','©');
ascii_para_str.set('0271','¦');
ascii_para_str.set('0272','¦');
ascii_para_str.set('0273','+');
ascii_para_str.set('0274','+');
ascii_para_str.set('0275','¢');
ascii_para_str.set('0276','¥');
ascii_para_str.set('0277','+');
ascii_para_str.set('0300','+');
ascii_para_str.set('0301','-');
ascii_para_str.set('0302','-');
ascii_para_str.set('0303','+');
ascii_para_str.set('0304','-');
ascii_para_str.set('0305','+');
ascii_para_str.set('0306','ã');
ascii_para_str.set('0307','Ã');
ascii_para_str.set('0310','+');
ascii_para_str.set('0311','+');
ascii_para_str.set('0312','-');
ascii_para_str.set('0313','-');
ascii_para_str.set('0314','¦');
ascii_para_str.set('0315','-');
ascii_para_str.set('0316','+');
ascii_para_str.set('0317','¤');
ascii_para_str.set('0320','ð');
ascii_para_str.set('0321','Ð');
ascii_para_str.set('0322','Ê');
ascii_para_str.set('0323','Ë');
ascii_para_str.set('0324','È');
ascii_para_str.set('0325','i');
ascii_para_str.set('0326','Í');
ascii_para_str.set('0327','Î');
ascii_para_str.set('0330','Ï');
ascii_para_str.set('0331','+');
ascii_para_str.set('0332','+');
ascii_para_str.set('0333','_');
ascii_para_str.set('0334','_');
ascii_para_str.set('0335','¦');
ascii_para_str.set('0336','Ì');
ascii_para_str.set('0337','_');
ascii_para_str.set('0340','Ó');
ascii_para_str.set('0341','ß');
ascii_para_str.set('0342','Ô');
ascii_para_str.set('0343','Ò');
ascii_para_str.set('0344','Õ');
ascii_para_str.set('0345','Õ');
ascii_para_str.set('0346','µ');
ascii_para_str.set('0347','Þ');
ascii_para_str.set('0350','Þ');
ascii_para_str.set('0351','Ú');
ascii_para_str.set('0352','Û');
ascii_para_str.set('0353','Ù');
ascii_para_str.set('0354','ý');
ascii_para_str.set('0355','Ý');
ascii_para_str.set('0356','¯');
ascii_para_str.set('0357','´');
ascii_para_str.set('0360',' ');
ascii_para_str.set('0361','±');
ascii_para_str.set('0362','_');
ascii_para_str.set('0363','¾');
ascii_para_str.set('0364','¶');
ascii_para_str.set('0365','§');
ascii_para_str.set('0366','÷');
ascii_para_str.set('0367','¸');
ascii_para_str.set('0370','°');
ascii_para_str.set('0371','¨');
ascii_para_str.set('0372','·');
ascii_para_str.set('0373','¹');
ascii_para_str.set('0374','³');
ascii_para_str.set('0375','²');
ascii_para_str.set('0376','_');
ascii_para_str.set('0377',' ');

function StringParaAscII(texto){
    var convertido = '';
    var ate = 0;
    for (var i = 0; i < texto.length; i++){
        ate = i + 1;
        convertido = convertido + str_para_ascii.get(texto.substring(i,ate));
    }
    return convertido;
}

function AscIIParaString(texto){
    var convertido = '';
    var ate = 0;
    for (var i = 0; i < texto.length; i = i + 4){
        ate = i + 4;
        convertido = convertido + ascii_para_str.get(texto.substring(i,ate));
    }
    return convertido;
}

function tratamentoNomeCampo(nome){
    var texto = nome.toString();
    texto = texto.toLowerCase();
    texto = texto.replace(ExpReg.FORMULARIO_LETRA_A[0], ExpReg.FORMULARIO_LETRA_A[1]);
    texto = texto.replace(ExpReg.FORMULARIO_LETRA_I[0], ExpReg.FORMULARIO_LETRA_I[1]);
    texto = texto.replace(ExpReg.FORMULARIO_LETRA_O[0], ExpReg.FORMULARIO_LETRA_O[1]);
    texto = texto.replace(ExpReg.FORMULARIO_LETRA_U[0], ExpReg.FORMULARIO_LETRA_U[1]);
    texto = texto.replace(ExpReg.FORMULARIO_LETRA_C[0], ExpReg.FORMULARIO_LETRA_C[1]);
    texto = texto.replace(ExpReg.FORMULARIO_CARACTERES_ESPECIAIS[0], ExpReg.FORMULARIO_CARACTERES_ESPECIAIS[1]);
    texto = texto.replace(ExpReg.CSS_ESPACO_EM_BRANCO[0], ExpReg.CSS_ESPACO_EM_BRANCO[1]);
    return texto;
};

export {StringParaAscII, AscIIParaString, tratamentoNomeCampo};