import React from "react";
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const EstiloMenu = styled.nav`

   @media (min-width: 320px) and (max-width: 480px) {

    float: left;
    width: 100%;
    margin: 0px 0px;
    padding: 20px 0px 20px 0px;
    background-color: ${({theme}) => theme.tmMenuFundo};
    display: block;  
    /* background-color: #0081C9;  */

    .link{
        text-decoration: none;
        margin: 3px 3px;
        padding: 0px 10px 0px 10px;
        background-color: ${({theme}) => theme.tmMenuFundo};
        cursor: pointer;
        text-transform:uppercase;
        font-size: 14px;
        font-weight: 600;
        font-weight: bold;
        color: ${({theme}) => theme.tmMenuFonte};;
        /* color: #38E54D; */
    }

    .linkDestacado{
        text-decoration: underline;
        margin: 3px 3px;
        padding: 0px 10px 0px 10px;
        background-color: ${({theme}) => theme.tmMenuFundo};
        cursor: pointer;
        text-transform:uppercase;
        font-size: 14px;
        font-weight: 600;
        font-weight: bold;
        color: ${({theme}) => theme.tmMenuFonte};
        /* color: #38E54D; */
    }

   }


   @media (min-width: 481px) {
    float: left;
    width: 100%;
    margin: 0px 0px;
    padding: 20px 0px 20px 0px;
    background-color: ${({theme}) => theme.tmMenuFundo};
    display: table; 

    .link{
        text-decoration: none;
        margin: 15px;
        padding: 0px 15px 0px 15px;
        background-color: ${({theme}) => theme.tmMenuFundo};
        cursor: pointer;
        text-transform:uppercase;
        font-size: 20px;
        font-weight: 600;
        font-weight: bold;
        color: ${({theme}) => theme.tmMenuFonte};;
    }

    .linkDestacado{
        text-decoration: underline;
        margin: 15px;
        padding: 0px 15px 0px 15px;
        background-color: ${({theme}) => theme.tmMenuFundo};
        cursor: pointer;
        text-transform:uppercase;
        font-size: 20px;
        font-weight: 600;
        font-weight: bold;
        color: ${({theme}) => theme.tmMenuFonte};
    }

}

`;


export default function Menu (){
    const localizacao = useLocation();

    return(
        <>
        <EstiloMenu id='menu'>
            <Link className={`${localizacao.pathname === '/' ? 'linkDestacado' : 'link'}`} to="/">
                Home
            </Link>
            <Link className={`${localizacao.pathname === '/editortexto' ? 'linkDestacado' :'link'}`} to="/editortexto">
                Editor
            </Link>
            <Link className={`${localizacao.pathname === '/relatorios/4' ? 'linkDestacado' :'link'}`} to="/relatorios/4">
                Relatórios
            </Link>
            <Link className={`${localizacao.pathname === '/indice/0' ? 'linkDestacado' :'link'}`} to={'/indice/0'}>
                Grupo
            </Link>
            <Link className={`${localizacao.pathname === `/blog/3000` ? 'linkDestacado' :'link'}`} to={`/blog/3000`}>
                Blog
            </Link>
        </EstiloMenu>

        </>

    );
};
